import { updateScoresOneEntryOnly } from "./calc";
import { PARTY_DATA, Parties, getCurrentPartyOrder } from "./config";

export function isInternalVersion() {
  return (new URL(document.location).pathname === '/v-internal');
}

export function getScoresFromUrl() {
  let params = (new URL(document.location)).searchParams;
  let remainderKey = Parties.POZOSTALI;

  let partyOrder = getCurrentPartyOrder();

  let initialScores = {};
  let total = 0;
  for (let key of partyOrder) {
    if (key == remainderKey)
      continue;
    let value = params.get(key.description);
    let floatValue = value ? parseFloat(value) : 0;
    initialScores[key] = floatValue;
    total += floatValue;
  }

  if (total > 0) {
    initialScores[remainderKey] = 100 - total;
    return updateScoresOneEntryOnly(partyOrder, initialScores, remainderKey, 100 - total);
  }

  // if no params in URL, use default scores
  return Object.fromEntries(partyOrder.map(
    key => [key, PARTY_DATA[key].score])
  );
}

export function doesIncludeThirdWay() {
  let params = (new URL(document.location)).searchParams;
  return params.has(Parties.TRZECIA_DROGA.description)
    || (!params.has(Parties.PSL.description)
      && !params.has(Parties.PL2050.description));
}

export function isLocalhost() {
  let hostname = (new URL(document.location)).hostname;
  return hostname === 'localhost';
}

export function buildUrlFromScores(scores) {
  return `${location.origin}/?${buildParamsFromScores(scores)}`;
}

function buildParamsFromScores(scores) {
  let params = Object.fromEntries(
    Object.getOwnPropertySymbols(scores).map(
      key => [key.description, scores[key]]
    )
  );
  return new URLSearchParams(params).toString();
}

export function replaceCurrentUrl(scores) {
  window.history.replaceState({}, '', `${location.pathname}?${buildParamsFromScores(scores)}`);
}


export function resetCurrentUrl() {
  window.history.replaceState({}, '', '/');
}