export function getSeatsInPolish(value) {
  if (value == 1) {
    return 'mandat';
  } else if (value % 100 > 10 && value % 100 < 20) {
    return 'mandatów';
  } else if ([2, 3, 4].includes(value % 10)) {
    return 'mandaty';
  }
  return 'mandatów';
}

export function getDemocraticCoalitionName(lowercase = false) {
  let name = 'Koalicja rządząca';
  if (lowercase) {
    return name.toLowerCase();
  }
  return name;
}

export function getCoalitionAccusative(lowercase = false) {
  let name = 'Koalicji';
  if (lowercase) {
    return name.toLowerCase();
  }
  return name;
}