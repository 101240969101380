import React from "react";
import CookieConsent from "react-cookie-consent";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import { Calculator } from '/src/pages/calculator.js';
import { SingleListExplanation } from '/src/pages/why.js';
import { ShareButtons } from '/src/sharing.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';

import logo from '/images/logo.png';
import { GoToCalculatorButton, GoToSingleListButton, GoToDHondtExplanationButton } from "/src/navigation";
import { DhondtMethodExplanation } from "./pages/dhondt";

export function App() {
  return (
    <Router>
      <div>
        <div className="container-fluid">
          <header className="row header">
            <div className="col-12 col-md-7 col-xl-8">
              <a href="/">
                <h1>
                  <img src={logo} className="header-logo" />
                  <span className="header-text">kalkulator sejmowy</span>
                </h1>
              </a>
            </div>
            <div className="col-md-5 col-xl-4 d-md-flex d-none justify-content-end">
              <Routes>
                <Route path="/dlaczego" element={<GoToCalculatorButton />} />
                <Route path="/dlaczego-wspolna-lista" element={<GoToCalculatorButton />} />
                <Route path="/metoda-dhondta" element={<GoToCalculatorButton />} />
                <Route path="*" element={<GoToDHondtExplanationButton />} />
              </Routes>
            </div>
          </header>
        </div>
        <Routes>
          <Route path="/dlaczego" element={<SingleListExplanation />} />
          <Route path="/dlaczego-wspolna-lista" element={<SingleListExplanation />} />
          <Route path="/metoda-dhondta" element={<DhondtMethodExplanation />} />
          <Route path="*" element={<Calculator />} />
        </Routes>
        <CustomCookieConsent />
        <div className="row footer p-2 p-sm-4">
          <div className="col-6">
            <p>Udostępnij wyniki: <ShareButtons url={document.location} /></p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} />
              &nbsp;
              Widzisz błąd? Masz pytanie?&nbsp;
              <a href="mailto:kontakt@kalkulatorsejmowy.pl">
                Napisz
              </a>
            </p>
          </div>
          <div className="col-6 text-end">
            <p>
              Copyright &copy; 2022-2023 kalkulatorsejmowy.pl
            </p>
            <p className="site-version">
              v5.0.0
            </p>
          </div>
        </div>
      </div>
    </Router>
  );
}

class CustomCookieConsent extends React.Component {
  constructor(props) {
    super(props);
    this.declineCookies = this.declineCookies.bind(this);
  }

  declineCookies() {
    // disable Google Analytics
    window['ga-disable-G-SLDDJV7LGG'] = true;
    // disable Firebase analytics
    window['ga-disable-G-RVLQ6D0KNN'] = true;
    // disable Meta Pixel
    ReactPixel.revokeConsent();
  }

  render() {
    return (
      <CookieConsent
        location="bottom"
        enableDeclineButton={true}
        buttonClasses="btn me-3 cookie-accept-button"
        declineButtonClasses="btn me-3 cookie-decline-button"
        containerClasses="cookie-banner"
        disableButtonStyles={true}
        buttonText="Akceptuję"
        declineButtonText="Nie akceptuję"
        onDecline={this.declineCookies}>
        Ta strona używa plików cookies.
      </CookieConsent>
    );
  }
}
