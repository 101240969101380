import * as colors from '../scss/colors.module.scss';
import { getCoalitionAccusative } from '/src/localisation.js';
import { doesIncludeThirdWay } from './router';

export const TOTAL_SEATS = 460;
export const SIMPLE_MAJORITY = 231;
export const VETO_MAJORITY = 276;
export const CONSTITUTIONAL_MAJORITY = 307;
export const THRESHOLD = 5;

export const Parties = Object.freeze({
  PIS: Symbol.for("pis"),
  KO: Symbol.for("ko"),
  PL2050: Symbol.for("pl2050"),
  LEWICA: Symbol.for("lewica"),
  KONFEDERACJA: Symbol.for("konfederacja"),
  PSL: Symbol.for("psl"),
  MN: Symbol.for("mn"),
  POZOSTALI: Symbol.for("pozostali"),
  NIEZDECYDOWANI: Symbol.for("niezdecydowani"),
  TRZECIA_DROGA: Symbol.for("trzecia_droga"),
});

export const GroupingOption = Object.freeze({
  GROUP_1: Symbol.for("group1"),
  GROUP_2: Symbol.for("group2"),
});

export const GROUP_DATA = {
  [GroupingOption.GROUP_1]: {
    sortOrder: 27,
    name: "Lista 1",
    nameAccusative: "Pierwszą listę " + getCoalitionAccusative(true),
    nameGenitive: "Pierwszej listy " + getCoalitionAccusative(true),
    nameLong: "Pierwsza lista " + getCoalitionAccusative(true),
    color: colors.bgGroup1,
    accentColor: colors.borderGroup1,
    threshold: 8,
  },
  [GroupingOption.GROUP_2]: {
    sortOrder: 28,
    name: "Lista 2",
    nameAccusative: "Drugą listę " + getCoalitionAccusative(true),
    nameGenitive: "Drugiej listy" + getCoalitionAccusative(true),
    nameLong: "Druga lista " + getCoalitionAccusative(true),
    color: colors.bgGroup2,
    accentColor: colors.borderGroup2,
    threshold: 8,
  }
};

export const PARTY_ORDER_WITHOUT_THIRD_WAY = [
  Parties.PIS,
  Parties.KO,
  Parties.PL2050,
  Parties.LEWICA,
  Parties.PSL,
  Parties.KONFEDERACJA,
  Parties.POZOSTALI,
];

export const DEFAULT_PARTY_ORDER = [
  Parties.PIS,
  Parties.KO,
  Parties.TRZECIA_DROGA,
  Parties.LEWICA,
  Parties.KONFEDERACJA,
  Parties.POZOSTALI,
];

export function getCurrentPartyOrder() {
  if (doesIncludeThirdWay()) {
    return DEFAULT_PARTY_ORDER;
  } else {
    return PARTY_ORDER_WITHOUT_THIRD_WAY;
  }
}

export const PARTY_DATA = {
  [Parties.PIS]: {
    sortOrder: 10,
    score: 35,
    name: "PiS",
    nameAccusative: "PiS",
    nameGenitive: "PiS",
    tooltip: "A właściwie to Zjednoczona Prawica (PiS + Solidarna/Suwerenna Polska + Partia Republikańska)",
    threshold: 5,
    canJoinGroup: false,
    color: colors.bgPis,
    accentColor: colors.borderPis,
  },
  [Parties.KONFEDERACJA]: {
    sortOrder: 20,
    score: 7,
    name: "Konfederacja",
    nameAccusative: "Konfederację",
    nameGenitive: "Konfederacji",
    threshold: 5,
    canJoinGroup: false,
    color: colors.bgKonfederacja,
    accentColor: colors.borderKonfederacja,
  },
  [Parties.KO]: {
    sortOrder: 30,
    score: 30,
    name: "Koalicja Obywatelska",
    nameAccusative: "Koalicję Obywatelską",
    nameGenitive: "Koalicji Obywatelskiej",
    threshold: 5,
    canJoinGroup: true,
    color: colors.bgKo,
    accentColor: colors.borderKo,
  },
  [Parties.LEWICA]: {
    sortOrder: 50,
    score: 9,
    name: "Lewica",
    nameAccusative: "Lewicę",
    nameGenitive: "Lewicy",
    threshold: 5,
    canJoinGroup: true,
    color: colors.bgLewica,
    accentColor: colors.borderLewica,
  },
  [Parties.TRZECIA_DROGA]: {
    sortOrder: 45,
    score: 14,
    name: "Trzecia Droga",
    nameAccusative: "Trzecią Drogę",
    nameGenitive: "Trzeciej Drogi",
    tooltip: "Polska 2050 + PSL",
    threshold: 8,
    canJoinGroup: true,
    color: colors.bgTrzeciaDroga,
    accentColor: colors.borderTrzeciaDroga,
  },
  [Parties.POZOSTALI]: {
    sortOrder: 26,
    score: 5,
    name: "Pozostali",
    nameAccusative: "Pozostałych",
    nameGenitive: "Pozostałych",
    tooltip: "Suma poparcia partii, które samodzielnie nie przekroczyły progu wyborczego, oraz niezdecydowanych wyborców. Głosy tej grupy przepadają i rozkładają się na wszystkie pozostałe partie proporcjonalnie do ich poparcia.",
    threshold: 100,
    canJoinGroup: true,
    color: colors.bgPozostali,
    accentColor: colors.borderPozostali,
  },
  [Parties.PL2050]: {
    sortOrder: 40,
    score: 10,
    name: "Polska 2050",
    nameAccusative: "Polskę 2050",
    nameGenitive: "Polski 2050",
    threshold: 5,
    canJoinGroup: true,
    color: colors.bgPl2050,
    accentColor: colors.borderPl2050,
  },
  [Parties.PSL]: {
    sortOrder: 60,
    score: 6,
    name: "PSL",
    nameAccusative: "PSL",
    nameGenitive: "PSL",
    threshold: 5,
    canJoinGroup: true,
    color: colors.bgPsl,
    accentColor: colors.borderPsl,
  },
  [Parties.MN]: {
    sortOrder: 80,
    score: 0,
    name: "Mniejszość Niemiecka",
    nameAccusative: "Mniejszość Niemiecką",
    nameGenitive: "Mniejszości Niemieckiej",
    threshold: 0,
    canJoinGroup: true,
    color: colors.bgMn,
    accentColor: colors.borderMn,
  },
  [Parties.NIEZDECYDOWANI]: {
    sortOrder: 25,
    score: 0,
    name: "Niezdecydowani",
    nameAccusative: "Niezdecydowanych",
    nameGenitive: "Niezdecydowanych",
    tooltip: "W niektórych sondażach jest to liczba osób, które nie wskazały swojej preferencji. W przypadku wyników wyborów jest to zawsze 0.",
    threshold: 100,
    canJoinGroup: false,
    color: colors.bgNiezdecydowani,
    accentColor: colors.borderNiezdecydowani,
  },
};

export const GOVERNMENT = [Parties.PIS, Parties.KONFEDERACJA];
export const OPPOSITION = getCurrentPartyOrder().filter(p => !GOVERNMENT.includes(p) && isOther(p));

export function isGovernment(key) {
  return GOVERNMENT.includes(key);
}

export function isOpposition(key) {
  return !isGovernment(key);
}

export function isPis(key) {
  return key === Parties.PIS;
}

export function isKonfederacja(key) {
  return key === Parties.KONFEDERACJA;
}

export function isMN(key) {
  return key === Parties.MN;
}

export function isUndecided(key) {
  return key == Parties.NIEZDECYDOWANI;
}

export function isOther(key) {
  return key == Parties.POZOSTALI;
}

export function getKnownKeys(keys) {
  return keys.filter(key => !isUndecided(key));
}

export function getEligibleForSeatsKeys(keys) {
  return keys.filter(key => !isUndecided(key) && !isOther(key));
}

export function getThreshold(key) {
  if (PARTY_DATA.hasOwnProperty(key)) {
    return PARTY_DATA[key].threshold;
  } else if (GROUP_DATA.hasOwnProperty(key)) {
    return GROUP_DATA[key].threshold;
  } else {
    return THRESHOLD;
  }
}

export const PREVIOUS_RESULTS = {
  [Parties.PIS]: 35.38,
  [Parties.KO]: 30.70,
  [Parties.TRZECIA_DROGA]: 14.40,
  [Parties.LEWICA]: 8.61,
  [Parties.KONFEDERACJA]: 7.16,
  [Parties.POZOSTALI]: 3.75,
};

// Both PSL and PL2050 are part of TRZECIA_DROGA,
// so let's assume that their support is proportional to TRZECIA_DROGA's support
export const PAST_SUPPORT = {
  [Parties.PL2050]: {
    [Parties.TRZECIA_DROGA]: 100,
  },
  [Parties.PSL]: {
    [Parties.TRZECIA_DROGA]: 100,
  }
};

export const CONSTITUENCIES = [
  {
    id: 'legnica',
    number: 1,
    name: 'Legnica',
    seats: 12,
    eligibleVoters: 716400,
    validVotes: 501870,
    results: {
      [Parties.PIS]: 34.798,
      [Parties.KO]: 33.782,
      [Parties.TRZECIA_DROGA]: 10.751,
      [Parties.LEWICA]: 9.507,
      [Parties.KONFEDERACJA]: 6.33,
      [Parties.POZOSTALI]: 4.832,
    }
  },
  {
    id: 'walbrzych',
    number: 2,
    name: 'Wałbrzych',
    seats: 8,
    eligibleVoters: 476857,
    validVotes: 323360,
    results: {
      [Parties.PIS]: 33.337,
      [Parties.KO]: 37.168,
      [Parties.TRZECIA_DROGA]: 12.127,
      [Parties.LEWICA]: 7.981,
      [Parties.KONFEDERACJA]: 6.024,
      [Parties.POZOSTALI]: 3.363,
    }
  },
  {
    id: 'wroclaw',
    number: 3,
    name: 'Wrocław',
    seats: 14,
    eligibleVoters: 1006283,
    validVotes: 776054,
    results: {
      [Parties.PIS]: 26.66,
      [Parties.KO]: 36.945,
      [Parties.TRZECIA_DROGA]: 13.739,
      [Parties.LEWICA]: 11.351,
      [Parties.KONFEDERACJA]: 6.975,
      [Parties.POZOSTALI]: 4.33,
    }
  },
  {
    id: 'bydgoszcz',
    number: 4,
    name: 'Bydgoszcz',
    seats: 12,
    eligibleVoters: 745557,
    validVotes: 533919,
    results: {
      [Parties.PIS]: 30.455,
      [Parties.KO]: 35.008,
      [Parties.TRZECIA_DROGA]: 15.063,
      [Parties.LEWICA]: 9.919,
      [Parties.KONFEDERACJA]: 6.418,
      [Parties.POZOSTALI]: 3.137,
    }
  },
  {
    id: 'torun',
    number: 5,
    name: 'Toruń',
    seats: 13,
    eligibleVoters: 779436,
    validVotes: 537597,
    results: {
      [Parties.PIS]: 34.065,
      [Parties.KO]: 29.524,
      [Parties.TRZECIA_DROGA]: 15.682,
      [Parties.LEWICA]: 11.249,
      [Parties.KONFEDERACJA]: 6.368,
      [Parties.POZOSTALI]: 3.112,
    }
  },
  {
    id: 'lublin',
    number: 6,
    name: 'Lublin',
    seats: 15,
    eligibleVoters: 903524,
    validVotes: 648347,
    results: {
      [Parties.PIS]: 45.477,
      [Parties.KO]: 20.315,
      [Parties.TRZECIA_DROGA]: 15.87,
      [Parties.LEWICA]: 5.72,
      [Parties.KONFEDERACJA]: 8.379,
      [Parties.POZOSTALI]: 4.239,
    }
  },
  {
    id: 'chelm',
    number: 7,
    name: 'Chełm',
    seats: 12,
    eligibleVoters: 693111,
    validVotes: 456872,
    results: {
      [Parties.PIS]: 50.754,
      [Parties.KO]: 17.401,
      [Parties.TRZECIA_DROGA]: 13.04,
      [Parties.LEWICA]: 5.623,
      [Parties.KONFEDERACJA]: 7.791,
      [Parties.POZOSTALI]: 5.391,
    }
  },
  {
    id: 'zielona-gora',
    number: 8,
    name: 'Zielona Góra',
    seats: 12,
    eligibleVoters: 741917,
    validVotes: 517041,
    results: {
      [Parties.PIS]: 27.76,
      [Parties.KO]: 37.732,
      [Parties.TRZECIA_DROGA]: 15.073,
      [Parties.LEWICA]: 9.266,
      [Parties.KONFEDERACJA]: 6.512,
      [Parties.POZOSTALI]: 3.657,
    }
  },
  {
    id: 'lodz',
    number: 9,
    name: 'Łódź',
    seats: 10,
    eligibleVoters: 581567,
    validVotes: 456552,
    results: {
      [Parties.PIS]: 26.817,
      [Parties.KO]: 41.075,
      [Parties.TRZECIA_DROGA]: 11.89,
      [Parties.LEWICA]: 12.215,
      [Parties.KONFEDERACJA]: 5.57,
      [Parties.POZOSTALI]: 2.433,
    }
  },
  {
    id: 'piotrkow-trybunalski',
    number: 10,
    name: 'Piotrków Trybun...',
    nameFull: 'Piotrków Trybunalski',
    seats: 9,
    eligibleVoters: 540069,
    validVotes: 396819,
    results: {
      [Parties.PIS]: 46.603,
      [Parties.KO]: 21.693,
      [Parties.TRZECIA_DROGA]: 13.729,
      [Parties.LEWICA]: 6.386,
      [Parties.KONFEDERACJA]: 7.622,
      [Parties.POZOSTALI]: 3.967,
    },
  },
  {
    id: 'sieradz',
    number: 11,
    name: 'Sieradz',
    seats: 12,
    eligibleVoters: 731875,
    validVotes: 533128,
    results: {
      [Parties.PIS]: 41.459,
      [Parties.KO]: 25.892,
      [Parties.TRZECIA_DROGA]: 14.502,
      [Parties.LEWICA]: 7.726,
      [Parties.KONFEDERACJA]: 6.824,
      [Parties.POZOSTALI]: 3.597,
    },
  },
  {
    id: 'krakow-i',
    number: 12,
    name: 'Kraków I',
    seats: 8,
    eligibleVoters: 492566,
    validVotes: 364671,
    results: {
      [Parties.PIS]: 42.863,
      [Parties.KO]: 24.243,
      [Parties.TRZECIA_DROGA]: 14.968,
      [Parties.LEWICA]: 6.043,
      [Parties.KONFEDERACJA]: 7.885,
      [Parties.POZOSTALI]: 3.998,
    },
  },
  {
    id: 'krakow-ii',
    number: 13,
    name: 'Kraków II',
    seats: 14,
    eligibleVoters: 957821,
    validVotes: 757522,
    results: {
      [Parties.PIS]: 30.683,
      [Parties.KO]: 30.732,
      [Parties.TRZECIA_DROGA]: 16.857,
      [Parties.LEWICA]: 11.04,
      [Parties.KONFEDERACJA]: 7.714,
      [Parties.POZOSTALI]: 2.974,
    },
  },
  {
    id: 'nowy-sacz',
    number: 14,
    name: 'Nowy Sącz',
    seats: 10,
    eligibleVoters: 609740,
    validVotes: 427292,
    results: {
      [Parties.PIS]: 53.731,
      [Parties.KO]: 16.102,
      [Parties.TRZECIA_DROGA]: 11.582,
      [Parties.LEWICA]: 3.181,
      [Parties.KONFEDERACJA]: 8.73,
      [Parties.POZOSTALI]: 6.674,
    },
  },
  {
    id: 'tarnow',
    number: 15,
    name: 'Tarnów',
    seats: 9,
    eligibleVoters: 568577,
    validVotes: 403591,
    results: {
      [Parties.PIS]: 48.671,
      [Parties.KO]: 17.02,
      [Parties.TRZECIA_DROGA]: 18.64,
      [Parties.LEWICA]: 4.002,
      [Parties.KONFEDERACJA]: 7.989,
      [Parties.POZOSTALI]: 3.678,
    },
  },
  {
    id: 'plock',
    number: 16,
    name: 'Płock',
    seats: 10,
    eligibleVoters: 627344,
    validVotes: 442567,
    results: {
      [Parties.PIS]: 44.11,
      [Parties.KO]: 22.402,
      [Parties.TRZECIA_DROGA]: 17.065,
      [Parties.LEWICA]: 6.518,
      [Parties.KONFEDERACJA]: 6.525,
      [Parties.POZOSTALI]: 3.38,
    },
  },
  {
    id: 'radom',
    number: 17,
    name: 'Radom',
    seats: 9,
    eligibleVoters: 534819,
    validVotes: 391202,
    results: {
      [Parties.PIS]: 48.675,
      [Parties.KO]: 20.962,
      [Parties.TRZECIA_DROGA]: 13.98,
      [Parties.LEWICA]: 5.336,
      [Parties.KONFEDERACJA]: 7.309,
      [Parties.POZOSTALI]: 3.738,
    },
  },
  {
    id: 'siedlce',
    number: 18,
    name: 'Siedlce',
    seats: 12,
    eligibleVoters: 736338,
    validVotes: 539408,
    results: {
      [Parties.PIS]: 48.616,
      [Parties.KO]: 18.706,
      [Parties.TRZECIA_DROGA]: 15.513,
      [Parties.LEWICA]: 4.848,
      [Parties.KONFEDERACJA]: 8.213,
      [Parties.POZOSTALI]: 4.104,
    },
  },
  {
    id: 'warszawa-i',
    number: 19,
    name: 'Warszawa I',
    seats: 20,
    eligibleVoters: 1993723,
    validVotes: 1714719,
    results: {
      [Parties.PIS]: 20.142,
      [Parties.KO]: 43.231,
      [Parties.TRZECIA_DROGA]: 13.246,
      [Parties.LEWICA]: 13.451,
      [Parties.KONFEDERACJA]: 7.244,
      [Parties.POZOSTALI]: 2.686,
    },
  },
  {
    id: 'warszawa-ii',
    number: 20,
    name: 'Warszawa II',
    seats: 12,
    eligibleVoters: 896957,
    validVotes: 730744,
    results: {
      [Parties.PIS]: 31.735,
      [Parties.KO]: 35.234,
      [Parties.TRZECIA_DROGA]: 15.065,
      [Parties.LEWICA]: 7.055,
      [Parties.KONFEDERACJA]: 7.058,
      [Parties.POZOSTALI]: 3.853,
    },
  },
  {
    id: 'opole',
    number: 21,
    name: 'Opole',
    seats: 12,
    eligibleVoters: 737189,
    validVotes: 479968,
    results: {
      [Parties.PIS]: 31.257,
      [Parties.KO]: 33.594,
      [Parties.TRZECIA_DROGA]: 12.741,
      [Parties.LEWICA]: 7.243,
      [Parties.KONFEDERACJA]: 6.49,
      [Parties.POZOSTALI]: 8.675,
    },
  },
  {
    id: 'krosno',
    number: 22,
    name: 'Krosno',
    seats: 11,
    eligibleVoters: 662734,
    validVotes: 441996,
    results: {
      [Parties.PIS]: 54.704,
      [Parties.KO]: 15.849,
      [Parties.TRZECIA_DROGA]: 13.787,
      [Parties.LEWICA]: 4.468,
      [Parties.KONFEDERACJA]: 8.615,
      [Parties.POZOSTALI]: 2.577,
    },
  },
  {
    id: 'rzeszow',
    number: 23,
    name: 'Rzeszów',
    seats: 15,
    eligibleVoters: 955621,
    validVotes: 673776,
    results: {
      [Parties.PIS]: 51.603,
      [Parties.KO]: 17.7,
      [Parties.TRZECIA_DROGA]: 12.419,
      [Parties.LEWICA]: 4.872,
      [Parties.KONFEDERACJA]: 9.477,
      [Parties.POZOSTALI]: 3.929,
    },
  },
  {
    id: 'bialystok',
    number: 24,
    name: 'Białystok',
    seats: 14,
    eligibleVoters: 881094,
    validVotes: 609244,
    results: {
      [Parties.PIS]: 42.393,
      [Parties.KO]: 20.841,
      [Parties.TRZECIA_DROGA]: 18.859,
      [Parties.LEWICA]: 4.838,
      [Parties.KONFEDERACJA]: 9.79,
      [Parties.POZOSTALI]: 3.279,
    },
  },
  {
    id: 'gdansk',
    number: 25,
    name: 'Gdańsk',
    seats: 12,
    eligibleVoters: 827151,
    validVotes: 616287,
    results: {
      [Parties.PIS]: 25.202,
      [Parties.KO]: 41.703,
      [Parties.TRZECIA_DROGA]: 14.701,
      [Parties.LEWICA]: 9.406,
      [Parties.KONFEDERACJA]: 6.232,
      [Parties.POZOSTALI]: 2.756,
    },
  },
  {
    id: 'slupsk',
    number: 26,
    name: 'Słupsk',
    seats: 14,
    eligibleVoters: 925938,
    validVotes: 682899,
    results: {
      [Parties.PIS]: 29.244,
      [Parties.KO]: 37.913,
      [Parties.TRZECIA_DROGA]: 13.588,
      [Parties.LEWICA]: 8.33,
      [Parties.KONFEDERACJA]: 7.205,
      [Parties.POZOSTALI]: 3.72,
    },
  },
  {
    id: 'bielsko-biala-i',
    number: 27,
    name: 'Bielsko-Biała I',
    seats: 9,
    eligibleVoters: 587203,
    validVotes: 445346,
    results: {
      [Parties.PIS]: 36.714,
      [Parties.KO]: 28.669,
      [Parties.TRZECIA_DROGA]: 14.546,
      [Parties.LEWICA]: 7.769,
      [Parties.KONFEDERACJA]: 7.839,
      [Parties.POZOSTALI]: 4.463,
    },
  },
  {
    id: 'czestochowa',
    number: 28,
    name: 'Częstochowa',
    seats: 7,
    eligibleVoters: 444929,
    validVotes: 323941,
    results: {
      [Parties.PIS]: 36.351,
      [Parties.KO]: 29.114,
      [Parties.TRZECIA_DROGA]: 14.724,
      [Parties.LEWICA]: 9.414,
      [Parties.KONFEDERACJA]: 6.562,
      [Parties.POZOSTALI]: 3.835,
    },
  },
  {
    id: 'katowice-i',
    number: 29,
    name: 'Katowice I',
    seats: 9,
    eligibleVoters: 550109,
    validVotes: 387408,
    results: {
      [Parties.PIS]: 30.156,
      [Parties.KO]: 36.063,
      [Parties.TRZECIA_DROGA]: 13.34,
      [Parties.LEWICA]: 9.208,
      [Parties.KONFEDERACJA]: 6.952,
      [Parties.POZOSTALI]: 4.281,
    },
  },
  {
    id: 'bielsko-biala-ii',
    number: 30,
    name: 'Bielsko-Biała II',
    seats: 9,
    eligibleVoters: 533844,
    validVotes: 381598,
    results: {
      [Parties.PIS]: 38.058,
      [Parties.KO]: 29.98,
      [Parties.TRZECIA_DROGA]: 12.454,
      [Parties.LEWICA]: 6.844,
      [Parties.KONFEDERACJA]: 8,
      [Parties.POZOSTALI]: 4.664,
    },
  },
  {
    id: 'katowice-ii',
    number: 31,
    name: 'Katowice II',
    seats: 12,
    eligibleVoters: 702884,
    validVotes: 526167,
    results: {
      [Parties.PIS]: 30.876,
      [Parties.KO]: 36.794,
      [Parties.TRZECIA_DROGA]: 13.271,
      [Parties.LEWICA]: 8.459,
      [Parties.KONFEDERACJA]: 6.697,
      [Parties.POZOSTALI]: 3.903,
    },
  },
  {
    id: 'katowice-iii',
    number: 32,
    name: 'Katowice III',
    seats: 9,
    eligibleVoters: 506119,
    validVotes: 377959,
    results: {
      [Parties.PIS]: 29.736,
      [Parties.KO]: 30.299,
      [Parties.TRZECIA_DROGA]: 9.848,
      [Parties.LEWICA]: 21.602,
      [Parties.KONFEDERACJA]: 5.692,
      [Parties.POZOSTALI]: 2.823,
    },
  },
  {
    id: 'kielce',
    number: 33,
    name: 'Kielce',
    seats: 16,
    eligibleVoters: 946053,
    validVotes: 659132,
    results: {
      [Parties.PIS]: 47.072,
      [Parties.KO]: 20.928,
      [Parties.TRZECIA_DROGA]: 13.802,
      [Parties.LEWICA]: 6.834,
      [Parties.KONFEDERACJA]: 6.554,
      [Parties.POZOSTALI]: 4.81,
    },
  },
  {
    id: 'elblag',
    number: 34,
    name: 'Elbląg',
    seats: 8,
    eligibleVoters: 455241,
    validVotes: 299380,
    results: {
      [Parties.PIS]: 35.197,
      [Parties.KO]: 31.869,
      [Parties.TRZECIA_DROGA]: 15.399,
      [Parties.LEWICA]: 8.106,
      [Parties.KONFEDERACJA]: 6.544,
      [Parties.POZOSTALI]: 2.885,
    },
  },
  {
    id: 'olsztyn',
    number: 35,
    name: 'Olsztyn',
    seats: 10,
    eligibleVoters: 589324,
    validVotes: 391058,
    results: {
      [Parties.PIS]: 32.331,
      [Parties.KO]: 33.074,
      [Parties.TRZECIA_DROGA]: 16.112,
      [Parties.LEWICA]: 8.089,
      [Parties.KONFEDERACJA]: 6.935,
      [Parties.POZOSTALI]: 3.459,
    },
  },
  {
    id: 'kalisz',
    number: 36,
    name: 'Kalisz',
    seats: 12,
    eligibleVoters: 754649,
    validVotes: 542267,
    results: {
      [Parties.PIS]: 35.852,
      [Parties.KO]: 28.582,
      [Parties.TRZECIA_DROGA]: 16.16,
      [Parties.LEWICA]: 8.524,
      [Parties.KONFEDERACJA]: 6.978,
      [Parties.POZOSTALI]: 3.904,
    },
  },
  {
    id: 'konin',
    number: 37,
    name: 'Konin',
    seats: 9,
    eligibleVoters: 586375,
    validVotes: 419243,
    results: {
      [Parties.PIS]: 38.687,
      [Parties.KO]: 23.991,
      [Parties.TRZECIA_DROGA]: 16.635,
      [Parties.LEWICA]: 9.484,
      [Parties.KONFEDERACJA]: 6.967,
      [Parties.POZOSTALI]: 4.236,
    },
  },
  {
    id: 'pila',
    number: 38,
    name: 'Piła',
    seats: 9,
    eligibleVoters: 581291,
    validVotes: 413235,
    results: {
      [Parties.PIS]: 29.112,
      [Parties.KO]: 34.875,
      [Parties.TRZECIA_DROGA]: 17.665,
      [Parties.LEWICA]: 7.835,
      [Parties.KONFEDERACJA]: 6.865,
      [Parties.POZOSTALI]: 3.648,
    },
  },
  {
    id: 'poznan',
    number: 39,
    name: 'Poznań',
    seats: 10,
    eligibleVoters: 723283,
    validVotes: 596038,
    results: {
      [Parties.PIS]: 19.574,
      [Parties.KO]: 44.088,
      [Parties.TRZECIA_DROGA]: 16.541,
      [Parties.LEWICA]: 12.305,
      [Parties.KONFEDERACJA]: 5.903,
      [Parties.POZOSTALI]: 1.589,
    },
  },
  {
    id: 'koszalin',
    number: 40,
    name: 'Koszalin',
    seats: 8,
    eligibleVoters: 475274,
    validVotes: 322149,
    results: {
      [Parties.PIS]: 31.359,
      [Parties.KO]: 38.686,
      [Parties.TRZECIA_DROGA]: 12.347,
      [Parties.LEWICA]: 8.723,
      [Parties.KONFEDERACJA]: 6.016,
      [Parties.POZOSTALI]: 2.869,
    },
  },
  {
    id: 'szczecin',
    number: 41,
    name: 'Szczecin',
    seats: 12,
    eligibleVoters: 771809,
    validVotes: 554308,
    results: {
      [Parties.PIS]: 28.788,
      [Parties.KO]: 40.127,
      [Parties.TRZECIA_DROGA]: 12.621,
      [Parties.LEWICA]: 9.387,
      [Parties.KONFEDERACJA]: 5.943,
      [Parties.POZOSTALI]: 3.134,
    },
  },
];

export const CONSTITUENCY_MAP =
  Object.fromEntries(CONSTITUENCIES.map(c => [c.id, c]));

