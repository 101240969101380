import React, { useState } from "react";
import {
  PARTY_DATA,
  GroupingOption,
  isGovernment,
  isUndecided,
  isOther,
} from '/src/config.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquare,
  faCircleQuestion,
  faSliders,
  faChevronDown,
  faChevronUp,
  faPlus,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Collapse, Tooltip, OverlayTrigger, Overlay } from "react-bootstrap";

export class Party extends React.Component {
  STEP_SIZE = 0.1;

  constructor(props) {
    super(props);
    this.handleScoreChange = this.handleScoreChange.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleNumberOnKeyDown = this.handleNumberOnKeyDown.bind(this);
    this.handleNumberInput = this.handleNumberInput.bind(this);
    this.handleScoreDownButton = this.handleScoreDownButton.bind(this);
    this.handleScoreUpButton = this.handleScoreUpButton.bind(this);
  }

  handleScoreChange(e) {
    this.props.onScoreChange(this.props.id, e.target.value);
    gtag('event', 'score_change', {
      'party': this.props.id.description,
      'value': e.target.value
    });
  }

  handleGroupChange(e) {
    this.props.onGroupChange(this.props.id, e.target.value);
    gtag('event', 'group_change', {
      'party': this.props.id.description,
      'value': e.target.value
    });
  }

  handleNumberOnKeyDown(e) {
    // Replace a comma with a dot, because number input doesn't accept commas.
    if (e.key == ',' || e.key == '.') {
      e.preventDefault();
      console.log(e.target.selectionStart);
      // split e.target.value into two parts: before and after the cursor
      let before = e.target.value.substring(0, e.target.selectionStart);
      let after = e.target.value.substring(e.target.selectionStart);
      // Replace the value with the new value
      if (!e.target.value.match(/[\.,]/)) {
        e.target.value = before + '.' + after;
        // Move the cursor to the right
        e.target.selectionStart = e.target.selectionEnd = before.length + 1;
      }
    }
  }

  handleNumberInput(e) {
    // Prevent invalid values
    if (e.target.value.match(/[^0-9\.]/)) {
      e.target.value = e.target.value.replace(/[^0-9\.]/g, '');
    }
    // Prevent out of range values
    if (e.target.value > 100 || e.target.value < 0) {
      e.target.value = this.props.score;
    }
  }

  handleScoreUpButton(e) {
    let value = (this.props.score + this.STEP_SIZE > 100) ? 100 : Math.floor(this.props.score * 10) / 10 + this.STEP_SIZE;
    this.props.onScoreChange(this.props.id, value);
    gtag('event', 'score_change_up', {
      'party': this.props.id.description,
      'value': this.props.score
    });
  }

  handleScoreDownButton(e) {
    let value = (this.props.score - this.STEP_SIZE < 0) ? 0 : Math.ceil(this.props.score * 10) / 10 - this.STEP_SIZE;
    this.props.onScoreChange(this.props.id, value);
    gtag('event', 'score_change_down', {
      'party': this.props.id.description,
      'value': this.props.score
    });
  }

  getPartyClass() {
    return 'party-' + this.props.id.description;
  }

  getButtons() {
    if (isGovernment(this.props.id)) {
      return (
        <div className="government-radio-msg pt-md-2">Startuje osobno</div>
      );
    } else if (isUndecided(this.props.id) || isOther(this.props.id)) {
      return (
        <div className="government-radio-msg pt-md-2">Nie wliczają się do wyników</div>
      )
    } else {
      return (
        <div className="btn-group d-flex pb-1 pt-2 pt-md-0">
          <input
            type="radio"
            id={this.props.id.description + '_' + GroupingOption.GROUP_1.description}
            name={this.props.id.description}
            value={GroupingOption.GROUP_1.description}
            disabled={!PARTY_DATA[this.props.id].canJoinGroup}
            defaultChecked={this.props.group == GroupingOption.GROUP_1}
            onChange={this.handleGroupChange}
            className={`btn-check ${'party-radio-' + GroupingOption.GROUP_1.description}`}
          />
          <label
            className={`btn btn-secondary ${'party-radio-' + GroupingOption.GROUP_1.description}`}
            htmlFor={this.props.id.description + '_' + GroupingOption.GROUP_1.description}
          >
            Lista&nbsp;1
          </label>
          <input
            type="radio"
            id={this.props.id.description + '_' + GroupingOption.GROUP_2.description}
            name={this.props.id.description}
            value={GroupingOption.GROUP_2.description}
            disabled={!PARTY_DATA[this.props.id].canJoinGroup}
            defaultChecked={this.props.group == GroupingOption.GROUP_2}
            onChange={this.handleGroupChange}
            className={`btn-check ${'party-radio-' + GroupingOption.GROUP_2.description}`}
          />
          <label
            className={`btn btn-secondary ${'party-radio-' + GroupingOption.GROUP_2.description}`}
            htmlFor={this.props.id.description + '_' + GroupingOption.GROUP_2.description}
          >
            Lista&nbsp;2
          </label>
          <input
            type="radio"
            id={this.props.id.description}
            name={this.props.id.description}
            value={this.props.id.description}
            disabled={!PARTY_DATA[this.props.id].canJoinGroup}
            defaultChecked={this.props.group == this.props.id}
            onChange={this.handleGroupChange}
            className={`btn-check ${'party-radio-' + this.props.id.description}`}
          />
          <label
            className={`btn btn-secondary ${'party-radio-' + this.props.id.description}`}
            htmlFor={this.props.id.description}>
            Osobno
          </label>
        </div>
      )
    }
  }

  render() {
    return (
      <fieldset className={`row form-group control-row pt-2 mb-1 ${this.getPartyClass()}`}>
        <div className="col-6 col-md-3 pe-1">
          <legend className="text-truncate">
            <span className={`${this.getPartyClass()} p-icon`}>
              <FontAwesomeIcon icon={faSquare} />
            </span>
            <span className="p-name text-truncate">
              {this.props.name}
              {this.props.tooltip &&
                <span>
                  &nbsp;
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-adjustScores">
                        {this.props.tooltip}
                      </Tooltip>
                    }
                  >
                    <Button variant="link" className="p-0 tooltip-icon"><FontAwesomeIcon icon={faCircleQuestion} /></Button>
                  </OverlayTrigger>
                </span>
              }
            </span>
          </legend>
        </div>
        <div className="col-4 col-md-2 g-0 d-flex">
          <div className="input-group">
            <input
              type="text"
              inputMode="decimal"
              pattern="[0-9]+([\.,][0-9]*)?"
              value={this.props.score}
              min="0"
              max="100"
              step="0.1"
              onChange={this.handleScoreChange}
              onKeyDown={this.handleNumberOnKeyDown}
              onInput={this.handleNumberInput}
              className="form-control party-score-input"
              disabled={isUndecided(this.props.id)}
            />
            <span className="input-group-text">%</span>
          </div>
          <div className="party-score-arrows">
            <Button
              className="party-score-input-arrow party-score-input-arrow-up"
              variant="light"
              onClick={this.handleScoreUpButton}
              disabled={isUndecided(this.props.id)}>
              <FontAwesomeIcon icon={faChevronUp} />
            </Button>
            <Button
              className="party-score-input-arrow party-score-input-arrow-down"
              variant="light"
              onClick={this.handleScoreDownButton}
              disabled={isUndecided(this.props.id)}>
              <FontAwesomeIcon icon={faChevronDown} />
            </Button>
          </div>
        </div>
        <CollapsibleRange
          score={this.props.score}
          partyClass={this.getPartyClass()}
          isUndecided={isUndecided(this.props.id)}
          handleScoreChange={this.handleScoreChange} />
        <div className={`${this.props.displaySingleListTools ? 'col-xxl-4 col-md-3' : 'col-xxl-7 col-md-7'} mt-2 d-none d-md-block`}>
          <input
            type="range"
            value={this.props.score}
            // debounceTimeout={20}
            min="0"
            max="100"
            step="0.1"
            onChange={this.handleScoreChange}
            className={`form-range ${this.getPartyClass()}`}
            disabled={isUndecided(this.props.id)}
          />
        </div>
        {this.props.displaySingleListTools &&
          <div className="col-xxl-3 col-md-4">
            {!this.props.hideGroupButtons && this.getButtons()}
            {this.props.hideGroupButtons && this.props.groupButtonsReplacement}
          </div>
        }
      </fieldset >
    );
  }
}

function CollapsibleRange(props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="col d-md-none gx-0 p-0 m-0">
        <Button
          className="btn-light"
          onClick={() => setOpen(!open)}
          aria-controls={`range-collapse-${props.partyClass}`}
          aria-expanded={open}
        >
          <FontAwesomeIcon icon={faSliders} />
        </Button>
      </div>
      <Collapse in={open} dimension="height">
        <div id={`range-collapse-${props.partyClass}`} className="col-xxl-4 col-md-3 mt-2">
          <input
            type="range"
            value={props.score}
            // debounceTimeout={20}
            min="0"
            max="100"
            step="0.1"
            className={`form-range ${props.partyClass}`}
            onChange={props.handleScoreChange}
            disabled={props.isUndecided}
          />
        </div>
      </Collapse>
    </>
  );
}

