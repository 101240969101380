import {
  PARTY_DATA,
  GroupingOption,
  isUndecided,
  isGovernment,
  isOther,
} from './config.js';

export function noBlocksGroupMapping(currentPartyOrder) {
  return Object.fromEntries(
    currentPartyOrder.map(
      key => [key, key]
    )
  );
}

export function singleBlockGroupMapping() {
  return Object.fromEntries(
    Object.getOwnPropertySymbols(PARTY_DATA).map(
      key => (isGovernment(key) || isUndecided(key) || isOther(key)) ? [key, key] : [key, GroupingOption.GROUP_1]
    )
  );
}
