import { getEligibleForSeatsKeys, isOpposition } from "./config";
import {
  calculateSeatsInSingleConstituency,
  getGroupScores,
  adjustConstituencyScores,
} from "/src/calc";
import { getKnownKeys, CONSTITUENCIES } from "/src/config";
import { normalise } from "/src/calc";
import { totalForGroup } from "./calc";

export function roundWastedVotes(value) {
  return Math.floor(value / 1000) * 1000;
}

export function roundVotes(value) {
  return Math.floor(value / 100) * 100;
}

export function getPartyVotesInConstituency(score, constituency) {
  return score / 100 * constituency.validVotes;
}

export function computeWastedVotes(scores, groupMapping, adjustScores) {
  let total = {}
  let wastedConstituencies = {};
  let groupScores = getGroupScores(scores, groupMapping);
  const totalVotes = totalVotesPerParty(scores, groupMapping, adjustScores);
  for (const c of CONSTITUENCIES) {
    const adjustedScores = adjustScores ? adjustConstituencyScores(c, scores, groupMapping) : groupScores;
    let seats = calculateSeatsInSingleConstituency(scores, groupMapping, c, totalVotes, adjustScores);
    const wastedVotes = computeWastedVotesInConstituency(c, seats, adjustedScores);
    for (const key of Object.getOwnPropertySymbols(wastedVotes)) {
      if (!total.hasOwnProperty(key)) {
        total[key] = 0;
        wastedConstituencies[key] = [];
      }
      total[key] += wastedVotes[key];
      if (wastedVotes[key] > 0) {
        wastedConstituencies[key].push(c.nameFull || c.name);
      }
    }
  }
  return { votes: total, constituencies: wastedConstituencies };
}

function computeWastedVotesInConstituency(constituency, seats, scores) {
  let wastedVotes = {};
  for (const key of Object.getOwnPropertySymbols(scores)) {
    wastedVotes[key] = 0;
    if (seats.hasOwnProperty(key) && seats[key] < 1) {
      wastedVotes[key] = getPartyVotesInConstituency(scores[key], constituency);
    }
  }
  return wastedVotes;
}

export function totalTurnout() {
  let total = 0;
  for (const c of CONSTITUENCIES) {
    total += c.validVotes;
  }
  return total;
}

export function totalVotesPerParty(scores, groupMapping, adjustScores) {
  let total = {};
  const groupScores = getGroupScores(scores, groupMapping);
  for (const c of CONSTITUENCIES) {
    const adjustedScores = adjustScores ? adjustConstituencyScores(c, scores, groupMapping) : groupScores;
    for (const key of Object.getOwnPropertySymbols(adjustedScores)) {
      if (!total.hasOwnProperty(key)) {
        total[key] = 0;
      }
      total[key] += getPartyVotesInConstituency(adjustedScores[key], c);
    }
  }
  return total;
}

export function computeTotalVotesInConstituency(scores, groupMapping, adjustScores, constituency) {
  const groupScores = getGroupScores(scores, groupMapping);
  const adjustedScores = adjustScores ? adjustConstituencyScores(constituency, scores, groupMapping) : groupScores;

  let constituencyVotes = {};
  for (const key of Object.getOwnPropertySymbols(adjustedScores)) {
    constituencyVotes[key] = getPartyVotesInConstituency(adjustedScores[key], constituency);
  }
  return constituencyVotes;
}

export function computeCostPerSeatInConstituency(scores, groupMapping, adjustScores, constituency) {
  const constituencyVotes = computeTotalVotesInConstituency(scores, groupMapping, adjustScores, constituency);

  const totalVotes = totalVotesPerParty(scores, groupMapping, adjustScores);
  const seats = calculateSeatsInSingleConstituency(scores, groupMapping, constituency, totalVotes, adjustScores);

  let costs = new Map();
  for (const key of getEligibleForSeatsKeys(Object.getOwnPropertySymbols(constituencyVotes))) {
    let cost = (seats[key] > 0) ? constituencyVotes[key] / seats[key] : Infinity;
    costs.set(key.description, cost);
  }
  return costs;
}

export function computePossibleGainsAndLossesInConstituency(scores, groupMapping, adjustScores, constituency) {
  const constituencyVotes = computeTotalVotesInConstituency(scores, groupMapping, adjustScores, constituency);
  const totalVotes = totalVotesPerParty(scores, groupMapping, adjustScores);

  let result = new Map();
  for (const key of getEligibleForSeatsKeys(Object.getOwnPropertySymbols(constituencyVotes))) {
    const seats = calculateSeatsInSingleConstituency(scores, groupMapping, constituency, totalVotes, adjustScores);
    const totalOppositionSeats = totalForGroup(seats, isOpposition)
    let variantSeats = seats;
    let votes = Object.assign({}, constituencyVotes);
    while (totalForGroup(variantSeats, isOpposition) <= totalOppositionSeats && votes[key] < constituency.eligibleVoters) {
      votes[key] += 1000;
      let normalisedVotes = normalise(votes, false);
      variantSeats = calculateSeatsInSingleConstituency(normalisedVotes, groupMapping, constituency, totalVotes, false);
    }
    let gain = (votes[key] > constituency.eligibleVoters) ? null : votes[key] - constituencyVotes[key];
    variantSeats = seats;
    votes = Object.assign({}, constituencyVotes);
    while (totalForGroup(variantSeats, isOpposition) >= totalOppositionSeats && votes[key] > 0) {
      votes[key] -= 1000;
      let normalisedVotes = normalise(votes, false);
      variantSeats = calculateSeatsInSingleConstituency(normalisedVotes, groupMapping, constituency, totalVotes, false);
    }
    let loss = (votes[key] < 0) ? null : constituencyVotes[key] - votes[key];
    result.set(key.description, [gain, loss]);
  }
  return result;
}

export function computeVoteTransferGainsAndLossesInConstituency(scores, groupMapping, adjustScores, constituency, fromParty, toParty) {
  const constituencyVotes = computeTotalVotesInConstituency(scores, groupMapping, adjustScores, constituency);
  const totalVotes = totalVotesPerParty(scores, groupMapping, adjustScores);

  const seats = calculateSeatsInSingleConstituency(scores, groupMapping, constituency, totalVotes, adjustScores);
  const totalOppositionSeats = totalForGroup(seats, isOpposition)
  let variantSeats = seats;
  let votes = Object.assign({}, constituencyVotes);
  while (
    totalForGroup(variantSeats, isOpposition) <= totalOppositionSeats
    && votes[fromParty] > 0
    && votes[toParty] < constituency.eligibleVoters
  ) {
    votes[fromParty] -= 1000;
    votes[toParty] += 1000;
    if (votes[fromParty] < 0) {
      return null;
    }
    let normalisedVotes = normalise(votes, false);
    variantSeats = calculateSeatsInSingleConstituency(normalisedVotes, groupMapping, constituency, totalVotes, false);
  }
  return constituencyVotes[fromParty] - votes[fromParty];
}