import {
  Link,
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandPointRight,
} from '@fortawesome/free-solid-svg-icons';

export function GoToCalculatorButton() {
  function countClick() {
    gtag('event', 'nav_GoToCalculatorButton');
  }

  return (
    <a href="/" className="d-block" onClick={countClick()}>
      <button className="btn btn-light btn-lg">
        <FontAwesomeIcon icon={faHandPointRight} />&nbsp;
        Przejdź do kalkulatora</button>
    </a>
  );
}

export function GoToSingleListButton() {
  function countClick() {
    gtag('event', 'nav_GoToSingleListButton');
  }

  return (
    <a href="/dlaczego-wspolna-lista" className="d-block" onClick={countClick()}>
      <button className="btn btn-primary primary-nav-button btn-lg">
        <FontAwesomeIcon icon={faHandPointRight} />&nbsp;
        Dlaczego wspólna lista?
      </button>
    </a>
  );
}

export function GoToDHondtExplanationButton(props) {
  function countClick() {
    gtag('event', 'nav_GoToDHondtExplanationButton');
  }

  return (
    <a href="/metoda-dhondta" className="d-block" onClick={countClick()}>
      <button className={`btn btn-lg ${props.primary ? 'btn-primary primary-nav-button' : 'btn-light'}`}>
        <FontAwesomeIcon icon={faHandPointRight} />&nbsp;
        Jak działa metoda D'Hondta?
      </button>
    </a>
  );
}

export function TryCalculatorButton() {
  function countClick() {
    gtag('event', 'nav_TryCalculatorButton');
  }

  return (
    <a href="/" className="d-block" onClick={countClick()}>
      <button className="btn btn-primary btn-lg primary-nav-button text-light">
        <FontAwesomeIcon icon={faHandPointRight} />&nbsp;
        Wypróbuj Kalkulator Sejmowy
      </button>
    </a>
  );
}


export function VisitMlodeKobietyDoSejmu() {
  function countClick() {
    gtag('event', 'nav_MlodeKobietyDoSejmu');
  }

  return (
    <a href="https://mlodekobietydosejmu.pl" onClick={countClick()}>
      <button className="btn btn-info btn-lg primary-nav-button text-light">
        <FontAwesomeIcon icon={faHandPointRight} />&nbsp;
        mlodekobietydosejmu.pl
      </button>
    </a>
  );
}

export function VisitWybierzTak() {
  function countClick() {
    gtag('event', 'nav_WybierzTak');
  }

  return (
    <a href="https://wybierztak.pl" onClick={countClick()}>
      <button className="btn btn-info btn-lg primary-nav-button text-light">
        <FontAwesomeIcon icon={faHandPointRight} />&nbsp;
        wybierztak.pl
      </button>
    </a>
  );
}