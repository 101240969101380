import React from "react";
import {
  calculateSeatsInConstituencies,
  updateScoresOneEntryOnly,
} from '/src/calc.js';
import {
  PARTY_DATA,
  DEFAULT_PARTY_ORDER,
  PARTY_ORDER_WITHOUT_THIRD_WAY,
  GroupingOption,
  isGovernment,
  isUndecided,
  isOther,
  Parties,
  PREVIOUS_RESULTS,
  getCurrentPartyOrder,
} from '/src/config.js';
import {
  getScoresFromUrl,
  buildUrlFromScores,
  replaceCurrentUrl,
  isInternalVersion,
  doesIncludeThirdWay,
} from '/src/router.js';
import { Result, Summary } from '/src/results.js';
import { noBlocksGroupMapping, singleBlockGroupMapping } from "/src/transform.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquare,
  faArrowTurnDown,
  faCircleInfo,
  faCircleQuestion,
  faEnvelope,
  faSliders,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faTwitter,
  faFacebookMessenger,
  faInstagram,
  faWhatsapp,
  faLinkedin,
  faReddit,
} from '@fortawesome/free-brands-svg-icons';
import { ShareButtons } from "/src/sharing.js";
import { Accordion, Button, Collapse, Tooltip, OverlayTrigger } from "react-bootstrap";
import { PostGraphic, Downloads } from '/src/downloads.js';
import { GoToCalculatorButton, GoToSingleListButton, VisitMlodeKobietyDoSejmu, VisitWybierzTak } from "/src/navigation";
import { computeWastedVotes } from "/src/wasted_votes";
import { totalVotesPerParty } from "../wasted_votes";
import { SIMPLE_MAJORITY } from "../config";
import { findWinningResult } from "/src/calc";
import { ConstituencyResults } from "/src/results";
import { Party } from "/src/controls";
import { GoToDHondtExplanationButton, VisitWybierzTak } from "../navigation";
import { getCoalitionAccusative } from "/src/localisation.js";

export class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.url = "https://kalkulatorsejmowy.pl";
    let initialScores = getScoresFromUrl();
    let includesThirdWay = doesIncludeThirdWay();
    let initialGroupMapping = Object.fromEntries(getCurrentPartyOrder().map(
      key => [key, key]
    ));
    const initialAdjustScores = true;
    this.state = {
      adjustScores: initialAdjustScores,
      scores: initialScores,
      delayedScores: initialScores,
      seats: calculateSeatsInConstituencies(initialScores, initialGroupMapping, initialAdjustScores),
      groupMapping: initialGroupMapping,
      wastedVotes: computeWastedVotes(initialScores, initialGroupMapping, initialAdjustScores),
      showUndecidedVoters: false,
      splitThirdWay: !includesThirdWay,
      displaySingleListTools: true,
      currentPartyOrder: getCurrentPartyOrder(),
    };
    this.handleScoreChange = this.handleScoreChange.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleThirdWaySplitSwitch = this.handleThirdWaySplitSwitch.bind(this);
    this.handleSingleListToolsSwitch = this.handleSingleListToolsSwitch.bind(this);
    this.handleAdjustConstituencyScoresSwitch = this.handleAdjustConstituencyScoresSwitch.bind(this);
    this.handledUndecidedVoters = this.handledUndecidedVoters.bind(this);
    this.load2023Results = this.load2023Results.bind(this);
    this.rangeTimeout = null;
    this.delayedScoresTimeout = null;
  }

  updateCurrentUrlWithTimeout(scores) {
    clearTimeout(this.rangeTimeout);
    this.rangeTimeout = setTimeout(() => {
      replaceCurrentUrl(scores);
    }, 500);
  }

  updateDelayedScoresWithTimeout(scores) {
    clearTimeout(this.delayedScoresTimeout);
    this.delayedScoresTimeout = setTimeout(() => {
      replaceCurrentUrl(scores);
      this.setState({
        delayedScores: scores,
        winningScores: findWinningResult(scores, this.state.groupMapping, this.state.adjustScores),
      });
    }, 50);
  }

  updatedSeatState(scores, groupMapping, adjustScores = false) {
    let seats = calculateSeatsInConstituencies(scores, groupMapping, adjustScores);
    return {
      seats: seats,
      wastedVotes: computeWastedVotes(scores, groupMapping, adjustScores),
    };
  }

  handleScoreChange(id, newScore) {
    let newScores = updateScoresOneEntryOnly(this.state.currentPartyOrder, this.state.scores, id, newScore);
    this.setState(
      Object.assign(
        {
          scores: newScores,
        },
        this.updatedSeatState(newScores, this.state.groupMapping, this.state.adjustScores)
      )
    );
    this.updateCurrentUrlWithTimeout(newScores);
    this.updateDelayedScoresWithTimeout(newScores);
  };

  handleMultiScoresChange(
    newScores,
    scores = this.state.scores,
    partyOrder = this.state.currentPartyOrder,
    groupMapping = this.state.groupMapping,
    callback = () => { }
  ) {
    let newScoresObject = Object.assign({}, scores);
    // Object.entries and Object.keys doesn't work with symbols
    const keysToConsider = Object.getOwnPropertySymbols(newScores);
    for (let key of keysToConsider) {
      newScoresObject = updateScoresOneEntryOnly(partyOrder, newScoresObject, key, newScores[key]);
    }
    this.setState(
      Object.assign(
        {
          scores: newScoresObject,
        },
        this.updatedSeatState(newScoresObject, groupMapping, this.state.adjustScores)
      ),
      () => {
        callback();
      }
    );
    // this.updateCurrentUrlWithTimeout(newScoresObject);
    // this.updateDelayedScoresWithTimeout(newScoresObject);
  };

  handleAdjustConstituencyScoresSwitch(e) {
    const newValue = e.target.checked;
    this.setState(
      Object.assign(
        {
          adjustScores: newValue,
        },
        this.updatedSeatState(this.state.scores, this.state.groupMapping, newValue)
      )
    );
  }

  handledUndecidedVoters(e) {
    const newValue = e.target.checked;
    this.setState({
      showUndecidedVoters: newValue,
    });
  }

  handleGroupChange(id, newGroup) {
    let newGroupMapping = Object.assign(
      {},
      this.state.groupMapping,
      { [id]: Symbol.for(newGroup) }
    );
    console.log(Object.assign(
      {
        groupMapping: newGroupMapping,
      },
      this.updatedSeatState(this.state.scores, newGroupMapping, this.state.adjustScores)
    ));
    this.setState(
      Object.assign(
        {
          groupMapping: newGroupMapping,
        },
        this.updatedSeatState(this.state.scores, newGroupMapping, this.state.adjustScores)
      )
    );
  }

  handleThirdWaySplitSwitch(e) {
    this.splitThirdWay(e.target.checked);
  }

  handleSingleListToolsSwitch(e) {
    this.setState({
      displaySingleListTools: e.target.checked,
    });
  }

  splitThirdWay(shouldSplit, callback = () => { }) {
    let scores = this.state.scores;
    let newScores = Object.assign({}, scores);
    let adjustedScores = {};
    let newPartyOrder = shouldSplit ? PARTY_ORDER_WITHOUT_THIRD_WAY : DEFAULT_PARTY_ORDER;
    // if we're splitting the Third Way, re-assign the scores
    if (shouldSplit) {
      adjustedScores = {
        [Parties.PL2050]: scores[Parties.TRZECIA_DROGA],
        [Parties.PSL]: 0,
      }
      newScores = Object.assign(
        newScores,
        adjustedScores,
      );
      delete newScores[Parties.TRZECIA_DROGA];
    } else {
      adjustedScores = {
        [Parties.TRZECIA_DROGA]: scores[Parties.PL2050] + scores[Parties.PSL],
      }
      newScores = Object.assign(
        newScores,
        adjustedScores,
      );
      delete newScores[Parties.PL2050];
      delete newScores[Parties.PSL];
    }

    // select all entries in the current group mapping that are in the current party order; set defaults for all new ones
    let newGroupMapping = Object.fromEntries(
      newPartyOrder.map(
        key => [key, (key in this.state.groupMapping ? this.state.groupMapping[key] : key)]
      )
    );
    this.setState(
      {
        splitThirdWay: shouldSplit,
        scores: newScores,
        currentPartyOrder: newPartyOrder,
        groupMapping: newGroupMapping,
      },
    );

    this.handleMultiScoresChange(adjustedScores, newScores, newPartyOrder, newGroupMapping, callback);
  }

  handleAdjustConstituencyScoresSwitch(e) {
    const newValue = e.target.checked;
    this.setState(
      Object.assign(
        {
          adjustScores: newValue,
        },
        this.updatedSeatState(this.state.scores, this.state.groupMapping, newValue)
      )
    );
  }

  handledUndecidedVoters(e) {
    const newValue = e.target.checked;
    this.setState(
      {
        showUndecidedVoters: newValue,
      },
    );
  }

  load2023Results() {
    let newScores = Object.fromEntries(this.state.currentPartyOrder.map(
      key => [key, (key in PREVIOUS_RESULTS ? PREVIOUS_RESULTS[key] : 0)])
    );
    this.setState(
      {
        scores: newScores,
      }
    );
    this.updateDelayedScoresWithTimeout(newScores);

    gtag('event', 'load_2023_results');
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-8 controls p-3 p-sm-4 d-flex flex-column align-items-start">
            <div className="col-12">
              <h2>
                <span className="header-number">1.</span>
                Zacznij tutaj
                <FontAwesomeIcon icon={faArrowTurnDown} className="start-here-icon" />
                lub
                <button
                  className="btn btn-primary load-2019-results-button primary-nav-button text-light"
                  onClick={this.load2023Results}
                >Wczytaj wyniki z 2023 roku&nbsp;
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="tooltip-load2023Results">
                        <p>
                          Ustawia poparcie partii równe ich wynikom z wyborów 15 października 2023 roku.
                        </p>
                      </Tooltip>
                    }
                  >
                    <Button variant="link" className="p-0 tooltip-icon"><FontAwesomeIcon icon={faCircleQuestion} /></Button>
                  </OverlayTrigger>
                </button>
              </h2>
              <p className="description mb-4">
              </p>
            </div>
            <div className="col-12">
              {/* input field that supports decimal numbers with both comma and dot */}
              <form>
                {this.state.currentPartyOrder.map((key) =>
                  <Party
                    key={key.description}
                    id={key}
                    name={PARTY_DATA[key].name}
                    tooltip={PARTY_DATA[key].tooltip}
                    score={this.state.scores[key]}
                    displaySingleListTools={this.state.displaySingleListTools}
                    group={this.state.groupMapping[key]}
                    onScoreChange={this.handleScoreChange}
                    onGroupChange={this.handleGroupChange}
                  />
                )}
              </form>
            </div>
            <div className="col-12 social mt-2">
              <p>Śledź Kalkulator Sejmowy na&nbsp;
                <a href="https://www.twitter.com/kalkulatorsejm">
                  <FontAwesomeIcon icon={faTwitter} />Twitterze
                </a>,&nbsp;
                <a href="https://www.facebook.com/kalkulatorsejmowy">
                  <FontAwesomeIcon icon={faFacebook} />Facebooku
                </a>&nbsp;i&nbsp;
                <a href="https://www.instagram.com/kalkulatorsejmowy/">
                  <FontAwesomeIcon icon={faInstagram} />Instagramie
                </a>.
              </p>
            </div>
            <Accordion className="col-12 mt-4 pt-2 invisible-accordion" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="extra-options">
                  Zaawansowane opcje
                </Accordion.Header>
                <Accordion.Body>
                  <form>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="adjustConstituencyScoresToggle"
                        defaultChecked={this.state.adjustScores}
                        onChange={this.handleAdjustConstituencyScoresSwitch} />
                      <span>
                        <label className="form-check-label" htmlFor="adjustConstituencyScoresToggle">
                          Włącz korekcję poparcia w okręgach na podstawie wyników wyborów w 2023 roku.
                        </label>
                        &nbsp;
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-adjustScores">
                              <p>
                                Włączenie tej opcji spowoduje przemnożenie wyników
                                w każdym okręgu proporcjonalnie do wyników wyborów
                                w tym samym okręgu w 2023 roku.
                                Wyniki Polski 2050 i PSL są korygowane proporcjonalnie do wyniku Trzeciej Drogi.
                              </p>
                              <p>
                                <b>
                                  To nie jest precyzyjny model. Uwiarygadnia tylko rozkład mandatów
                                  w okręgach o mniej typowym rozkładzie preferencji politycznych.
                                </b>
                              </p>
                              <p>
                                Wyłączenie korekcji powoduje brak możliwości rozstrzygnięcia remisów w okręgach
                                zgodnie z kodeksem wyborczym (Art. 232). Są one wtedy rozstrzygane arbitralnie.
                              </p>
                              <p>
                                Źródło danych: https://wybory.gov.pl/sejmsenat2023/pl/dane_w_arkuszach
                              </p>
                            </Tooltip>
                          }
                        >
                          <Button variant="link" className="p-0 tooltip-icon"><FontAwesomeIcon icon={faCircleQuestion} /></Button>
                        </OverlayTrigger>
                      </span>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="singleListToolsToggle"
                        checked={this.state.displaySingleListTools}
                        onChange={this.handleSingleListToolsSwitch} />
                      <span>
                        <label className="form-check-label" htmlFor="singleListToolsToggle">
                          Wyświetl narzędzia do łączenia list wyborczych.
                        </label>
                        &nbsp;
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-singleListToolsToggle">
                              <p>
                                Włączenie tej opcji pozwala na łączenie partii we wspólne listy wyborcze.
                              </p>
                            </Tooltip>
                          }
                        >
                          <Button variant="link" className="p-0 tooltip-icon"><FontAwesomeIcon icon={faCircleQuestion} /></Button>
                        </OverlayTrigger>
                      </span>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="thirdWaySplitToggle"
                        checked={this.state.splitThirdWay}
                        onChange={this.handleThirdWaySplitSwitch} />
                      <span>
                        <label className="form-check-label" htmlFor="thirdWaySplitToggle">
                          Podziel Trzecią Drogę na osobne listy: PSL i Polska 2050.
                        </label>
                        &nbsp;
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-thirdWaySplit">
                              <p>
                                Włączenie tej opcji powoduje rozdzielenie Trzeciej Drogi
                                na listy dla PSL i Polski 2050 i pozwala na konfigurację
                                poparcia osobno dla każdej z nich.
                              </p>
                            </Tooltip>
                          }
                        >
                          <Button variant="link" className="p-0 tooltip-icon"><FontAwesomeIcon icon={faCircleQuestion} /></Button>
                        </OverlayTrigger>
                      </span>
                    </div>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="showUndecidedVotersToggle"
                        defaultChecked={this.state.showUndecidedVoters}
                        onChange={this.handledUndecidedVoters} />
                      <label className="form-check-label" htmlFor="showUndecidedVotersToggle">
                        Pokaż pozostałych wyborców na wykresach (partie poniżej progu wyborczego i niezdecydowanych).
                      </label>
                      &nbsp;
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-undecidedVoters">
                            <p>
                              Poparcie partii, które nie przekroczyły progu wyborczego oraz niezdecydowani wyborcy
                              nie wliczają się do wyniku wyborów.
                              Głosy tej grupy przepadają i rozkładają się na wszystkie pozostałe partie proporcjonalnie do ich poparcia.
                            </p>
                          </Tooltip>
                        }
                      >
                        <Button variant="link" className="p-0 tooltip-icon"><FontAwesomeIcon icon={faCircleQuestion} /></Button>
                      </OverlayTrigger>
                    </div>
                  </form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-xl-4 summary p-3 p-sm-4">
            <h2>
              <span className="header-number">2.</span>
              Podział mandatów
            </h2>
            <Summary
              scores={this.state.scores}
              groupMapping={this.state.groupMapping}
              adjustScores={this.state.adjustScores}
              wastedVotes={this.state.wastedVotes} />
            <div className="container-fluid p-0 ps-2">
              <div className="row">
                {/* <div className="col-12 d-flex justify-content-center pt-1 pb-2">
                  <GoToSingleListButton />
                </div> */}
              </div>
              <div className="row">
                <div className="col p-1 sharing">
                  Udostępnij wyniki: <ShareButtons url={buildUrlFromScores(this.state.scores)} />
                </div>
              </div>
            </div>
          </div>
          <div className="row results-header g-0 p-3 p-sm-4">
            <div className="col-xl-4">
              <h2>
                <span className="header-number">3.</span>
                Szczegółowe wyniki
              </h2>
            </div>
            <div className="col-xl-8 pt-1">
              <p className="description">
                <FontAwesomeIcon icon={faCircleInfo} className="info-icon" />&nbsp;
                Poniżej możesz przeanalizować szczegółowe wyniki wyborów w różnych konfiguracjach.
              </p>
              <p className="description">
                <FontAwesomeIcon icon={faCircleInfo} className="info-icon" />&nbsp;
                Dla każdej konfiguracji, wykres na górze przedstawia podział mandatów w sejmie.
                Wykres poniżej przedstawia procentowe poparcie partii i blokow.
                Dla Twojej konfiguracji przedstawione są również symulowane wyniki w okręgach.
              </p>
              {this.state.adjustScores &&
                <p className="description mb-0">
                  <FontAwesomeIcon icon={faCircleInfo} className="info-icon" />&nbsp;
                  Poparcie Mniejszości Niemieckiej jest dodane na podstawie wyniku w wyborach w 2023 roku.
                </p>
              }
              <div className="d-md-none d-flex justify-content-center mt-4">
                <GoToDHondtExplanationButton primary={true} />
              </div>
            </div>
          </div>
          <Result
            title="Twoja konfiguracja"
            elementId="results-your-configuration"
            scores={this.state.delayedScores}
            groupMapping={this.state.groupMapping}
            adjustScores={this.state.adjustScores}
            displayConstituencies={false}
            showUndecidedVoters={this.state.showUndecidedVoters} />
          <ConstituencyResults
            title="Wyniki w okręgach"
            elementId="results-constituencies"
            scores={this.state.delayedScores}
            groupMapping={this.state.groupMapping}
            adjustScores={this.state.adjustScores}
            displayConstituencies={false}
            showUndecidedVoters={this.state.showUndecidedVoters}
          />
          <Result
            title={`Wspólna lista całej ${getCoalitionAccusative(true)}`}
            elementId="results-single-group"
            scores={this.state.delayedScores}
            groupMapping={singleBlockGroupMapping()}
            adjustScores={this.state.adjustScores}
            displayConstituencies={false}
            showUndecidedVoters={this.state.showUndecidedVoters} />
          {
            isInternalVersion() &&
            <div>
              <PostGraphic
                scores={this.state.delayedScores}
                adjustScores={this.state.adjustScores}
                currentPartyOrder={this.state.currentPartyOrder} />
              <Downloads />
            </div>
          }
        </div >
      </div >
    );
  }
}

