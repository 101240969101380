import React from "react";
import { Helmet } from "react-helmet";
import {
  roundToDecimal,
  calculateSeats,
  roundToDecimal,
  getKnownScores,
  getGroupScores,
} from '/src/calc.js';
import {
  PARTY_DATA,
  GROUP_DATA,
  DEFAULT_PARTY_ORDER,
  GroupingOption,
  Parties,
} from '/src/config.js';
import { BarChart, BadMessage, GoodMessage } from '/src/results.js';
import { noBlocksGroupMapping } from "/src/transform.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquare,
  faHandPointRight,
} from '@fortawesome/free-solid-svg-icons';
import { Accordion, Tooltip } from "react-bootstrap";
import { TryCalculatorButton } from "/src/navigation";
import { ShareButtons, ExplanationSharingButtons } from "/src/sharing";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { getDemocraticCoalitionName } from "/src/localisation.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  annotationPlugin,
);

const TOTAL_VOTES = {
  [Parties.PIS]: 33.4,
  [Parties.KO]: 28.5,
  [Parties.LEWICA]: 10.7,
  [Parties.TRZECIA_DROGA]: 11,
  [Parties.KONFEDERACJA]: 14.5,
  [Parties.POZOSTALI]: 1.9,
}

const CONSTITUENCY_EXAMPLE_1_SCORES = {
  [Parties.PIS]: 33,
  [Parties.KO]: 31,
  [Parties.LEWICA]: 11,
  [Parties.TRZECIA_DROGA]: 12,
  [Parties.KONFEDERACJA]: 13,
  [Parties.POZOSTALI]: 0,
};
const CONSTITUENCY_EXAMPLE_1_2_SCORES = {
  [Parties.PIS]: 33,
  [Parties.KO]: 31,
  [Parties.LEWICA]: 11,
  [Parties.TRZECIA_DROGA]: 13,
  [Parties.KONFEDERACJA]: 12,
  [Parties.POZOSTALI]: 0,
};
const CONSTITUENCY_EXAMPLE_1_3_SCORES = {
  [Parties.PIS]: 33,
  [Parties.KO]: 31,
  [Parties.LEWICA]: 12,
  [Parties.TRZECIA_DROGA]: 13,
  [Parties.KONFEDERACJA]: 11,
  [Parties.POZOSTALI]: 0,
};
const CONSTITUENCY_EXAMPLE_1_SEATS = 7;

const CONSTITUENCY_EXAMPLE_2_SCORES = {
  [Parties.PIS]: 33,
  [Parties.KO]: 31,
  [Parties.LEWICA]: 11,
  [Parties.TRZECIA_DROGA]: 12,
  [Parties.KONFEDERACJA]: 13,
  [Parties.POZOSTALI]: 0,
};
const CONSTITUENCY_EXAMPLE_2_SEATS = 7;
const CONSTITUENCY_EXAMPLE_2_GROUP_MAPPING = {
  [Parties.PIS]: Parties.PIS,
  [Parties.KO]: GroupingOption.GROUP_1,
  [Parties.LEWICA]: GroupingOption.GROUP_1,
  [Parties.TRZECIA_DROGA]: Parties.TRZECIA_DROGA,
  [Parties.KONFEDERACJA]: Parties.KONFEDERACJA,
  [Parties.POZOSTALI]: Parties.POZOSTALI,
}

const CONSTITUENCY_EXAMPLE_3_SCORES = {
  [Parties.PIS]: 35,
  [Parties.KO]: 28,
  [Parties.LEWICA]: 10,
  [Parties.TRZECIA_DROGA]: 13,
  [Parties.KONFEDERACJA]: 14,
  [Parties.POZOSTALI]: 0,
};
const CONSTITUENCY_EXAMPLE_3_SEATS = 7;
const CONSTITUENCY_EXAMPLE_3_GROUP_MAPPING = {
  [Parties.PIS]: Parties.PIS,
  [Parties.KO]: GroupingOption.GROUP_1,
  [Parties.LEWICA]: GroupingOption.GROUP_1,
  [Parties.TRZECIA_DROGA]: Parties.TRZECIA_DROGA,
  [Parties.KONFEDERACJA]: Parties.KONFEDERACJA,
  [Parties.POZOSTALI]: Parties.POZOSTALI,
}

const CONSTITUENCY_EXAMPLE_4_SCORES = {
  [Parties.PIS]: 36,
  [Parties.KO]: 29,
  [Parties.LEWICA]: 10,
  [Parties.TRZECIA_DROGA]: 10,
  [Parties.KONFEDERACJA]: 15,
  [Parties.POZOSTALI]: 0,
};
const CONSTITUENCY_EXAMPLE_4_SEATS = 7;
const CONSTITUENCY_EXAMPLE_4_GROUP_MAPPING = {
  [Parties.PIS]: Parties.PIS,
  [Parties.KO]: GroupingOption.GROUP_1,
  [Parties.LEWICA]: GroupingOption.GROUP_1,
  [Parties.TRZECIA_DROGA]: GroupingOption.GROUP_1,
  [Parties.KONFEDERACJA]: Parties.KONFEDERACJA,
  [Parties.POZOSTALI]: Parties.POZOSTALI,
}

const CONSTITUENCY_EXAMPLE_5_SCORES = {
  [Parties.PIS]: 46,
  [Parties.KO]: 21,
  [Parties.LEWICA]: 7,
  [Parties.TRZECIA_DROGA]: 8,
  [Parties.KONFEDERACJA]: 18,
  [Parties.POZOSTALI]: 0,
};
const CONSTITUENCY_EXAMPLE_5_SEATS = 7;
const CONSTITUENCY_EXAMPLE_5_GROUP_MAPPING = {
  [Parties.PIS]: Parties.PIS,
  [Parties.KO]: GroupingOption.GROUP_1,
  [Parties.LEWICA]: GroupingOption.GROUP_1,
  [Parties.TRZECIA_DROGA]: GroupingOption.GROUP_1,
  [Parties.KONFEDERACJA]: Parties.KONFEDERACJA,
  [Parties.POZOSTALI]: Parties.POZOSTALI,
}

export class SingleListExplanation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPartyOrder: DEFAULT_PARTY_ORDER,
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <Helmet>
          <title>Dlaczego wspólna lista? | Kalkulator Sejmowy</title>
          <meta property="og:title" content="Dlaczego wspólna lista? | Kalkulator Sejmowy" />
          <meta name="og:description" content="Dlaczego wspólna lista się opłaca? Zobacz jak metoda D'Hondta wpływa na podział mandatów 
          w indywidualnych okręgach wyborczych." />
          <meta name="description" content="Dlaczego wspólna lista się opłaca? Zobacz jak metoda D'Hondta wpływa na podział mandatów 
          w indywidualnych okręgach wyborczych." />
        </Helmet>
        <div className="row chapter chapter-0 mt-xl-2">
          <div className="col-xl-8">
            <h2>Dlaczego wspólna lista?</h2>
            <p>
              W ostatnich miesiącach pojawiło się wiele głosów za i przeciw wspólnej liście
              wszystkich partii opozycyjnych w wyborach do Sejmu. Głównym argumentem
              przemawiającym za wspólną listą jest czysta arytmetyka: <strong>ordynacja
                wyborcza do Sejmu faworyzuje partie o dużym poparciu kosztem partii o mniejszym poparciu</strong>,
              przyznając im bonus w postaci nieproporcjonalnie wyższej liczby mandatów.
              Najczęstszym argumentem przeciw jest demobilizacja wyborców poprzez <em>pozorny</em> brak wyboru
              (pozorny, ponieważ wspólna lista jest sojuszem taktycznym, nie programowym).
            </p>
            <p>Poniższy przykład ilustruje zarówno działanie ordynacji wyborczej jak i efekty
              potencjalnej demobilizacji wyborców. Zaczniemy od krótkiego wyjaśnienia zasad ordynacji
              i metody D'Hondta (używanej do podziału mandatów), a następnie przeanalizujemy wpływ
              wspólnej listy na przykładzie konkretnego okręgu wyborczego.
            </p>

            <p>A teraz mały spoiler na zachętę. Porównanie trzech osobnych list uzyskujących w sumie 54% poparcia,
              z jedną listą zdobywającą tylko 36% poparcia
              w tym samym 7-mandatowym okręgu wyborczym:
            </p>
            <div className="row justify-content-center">
              <div className="col-xl-11 example-chart example-spoiler" >
                <ExampleChart
                  totalSeats={CONSTITUENCY_EXAMPLE_1_SEATS}
                  seatTickValues={[0, 3, 4, 7]}
                  seats={calculateSeats(
                    CONSTITUENCY_EXAMPLE_1_SCORES,
                    CONSTITUENCY_EXAMPLE_1_SCORES,
                    CONSTITUENCY_EXAMPLE_1_SEATS,
                    TOTAL_VOTES,
                  )}
                  scores={CONSTITUENCY_EXAMPLE_1_SCORES}
                  groupMapping={noBlocksGroupMapping(DEFAULT_PARTY_ORDER)}
                  adjustScores={false}
                  showUndecidedVoters={true} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-11 example-chart example-spoiler" >
                <ExampleChart
                  totalSeats={CONSTITUENCY_EXAMPLE_5_SEATS}
                  seatTickValues={[0, 3, 4, 7]}
                  seats={calculateSeats(
                    getGroupScores(CONSTITUENCY_EXAMPLE_5_SCORES, CONSTITUENCY_EXAMPLE_5_GROUP_MAPPING),
                    getGroupScores(CONSTITUENCY_EXAMPLE_5_SCORES, CONSTITUENCY_EXAMPLE_5_GROUP_MAPPING),
                    CONSTITUENCY_EXAMPLE_5_SEATS,
                    TOTAL_VOTES,
                  )}
                  scores={CONSTITUENCY_EXAMPLE_5_SCORES}
                  groupMapping={CONSTITUENCY_EXAMPLE_5_GROUP_MAPPING}
                  adjustScores={false}
                  showUndecidedVoters={true} />
              </div>
            </div>
            <p>Przyjrzymy się im dokładnie poniżej.</p>
          </div>
          <div className="col-xl-4 d-xl-block d-none">
            <ExplanationSharingButtons />
          </div>
        </div>
        <div className="row chapter chapter-1">
          <div className="col-xl-8">
            <h3>
              <span className="header-number">1.</span>
              Wprowadzenie
            </h3>
            <p>Przypomnijmy najpierw podstawowe informacje o wyborach do Sejmu:
              wybieranych jest <strong>460 posłów i posłanek</strong>. Głos oddaje się na
              tylko jedną listę wyborczą i na tylko jedno nazwisko na tej liście.
              Lista musi zdobyć co najmniej 5% głosów w skali
              całego kraju jeśli reprezentuje jedną partię lub 8% jeśli reprezentuje koalicję.
              W przypadku zdobycia przez którąś listę mniejszej liczby głosów, wszystkie oddane na nią głosy przepadają.
            </p>
            <p>
              Dwa aspekty ordynacji wyborczej w wyborach do Sejmu mają
              szczególny wpływ na ostateczny podział mandatów:
              <ul>
                <li>Podział dokonywany jest za pomocą <strong>metody D'Hondta.</strong></li>
                <li>Podział odbywa się <strong>osobno w 41 okręgach wyborczych</strong>.
                  W każdym z nich rozdzielanych jest <strong>od 7 do 20 mandatów.</strong>
                </li>
              </ul>
            </p>
            <p>
              Żaden z tych aspektów nie jest zły sam w sobie.
              Gdyby rozdzielano wszystkie 460 mandatów za pomocą metody D'Hondta
              w jednym wielkim ogólnopolskim okręgu, podział byłby dość sprawiedliwy.
              Jednak połączenie małych okręgów
              z metodą D'Hondta <strong>prowadzi do silnego faworyzowania największych partii</strong>,
              co zostanie zaprezentowane poniżej.
            </p>
          </div>
        </div>
        <div className="row chapter chapter-2">
          <div className="col-xl-8">
            <h3>
              <span className="header-number">2.</span>
              O co chodzi w metodzie D'Hondta?
            </h3>
            <p>Jest kilka równoważnych sposobów przedstawienia metody D'Hondta
              i dokładny opis można znaleźć m.in. <a href="https://pl.wikipedia.org/wiki/Metoda_D%E2%80%99Hondta">w Wikipedii</a>.
              Przyjrzyjmy się jednak tej metodzie w skrócie.
            </p>
            <h4>Metoda D'Hondta</h4>
            <p>Załóżmy, że mamy pewną liczbę mandatów do rozdania i znamy liczbę głosów oddanych
              na każdą listę wyborczą. Żeby je rozdać, rozegramy dokładnie tyle rund ile jest mandatów do rozdania.</p>
            <p>
              W każdej rundzie podzielimy liczbę głosów oddanych na każdą z list przez liczbę mandatów, które
              tej liście już przypadły powiększoną o 1.
              (Na przykład lista która otrzymała 100 głosów i już 3 mandaty, do tej rundy przystąpi z wynikiem: 100/(3+1)=25.)
              Lista, której przypadnie najwyższy wynik w danej rundzie, otrzyma kolejny mandat.
            </p>
            <p>
              Kiedy już wiemy ile mandatów przypadło każdej z list,
              wybieramy z każdej z nich tych kandydatów, którzy otrzymali najwięcej głosów.
            </p>
            <Accordion className="col-12 mt-4 pt-2 invisible-accordion" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="extra-options">

                  <h4><FontAwesomeIcon icon={faHandPointRight} />&nbsp;Rozwiń przykład</h4>
                </Accordion.Header>
                <Accordion.Body className="dhondt-example">
                  <h4>Przykład</h4>
                  <p>Wyobrażmy sobie, że zorganizowaliśmy wybory w klubie sportowym w celu wybrania 4-osobowego zarządu,
                    do których przystąpiły trzy partie:{' '}
                    <mark className="party-a">Rowerzystów</mark>,{' '}
                    <mark className="party-b">Biegaczy</mark>,
                    i <mark className="party-c">Pływaków</mark>.{' '}
                    Głosowało 100 osób i otrzymaliśmy następujące wyniki:
                    <ul>
                      <li><mark className="party-a">50 głosów na Rowerzystów</mark>,</li>
                      <li><mark className="party-b">30 głosów na Biegaczy</mark>,</li>
                      <li><mark className="party-c">20 głosów na Pływaków</mark>.</li>
                    </ul>
                  </p>
                  <p>
                    Do rozdania są 4 mandaty, rozegramy więc 4 rundy.
                  </p>
                  <p>
                    <ol>
                      <li>W pierwszej rundzie żadna z list nie otrzymała jeszcze mandatów, więc{' '}
                        Rowerzyści mają wynik 50/(0+1)=50,{' '}
                        Biegacze 30/(0+1)=30,
                        a Pływacy 20/(0+1)=20. <strong><mark className="party-a">Rowerzyści</mark> wygrywają</strong> i otrzymują 1 mandat.
                      </li>
                      <li>W drugiej rundzie, Rowerzyści mają wynik 50/(1+1)=25, ponieważ otrzymali już jeden mandat.
                        Biegacze nadal mają wynik 30/(0+1)=30,
                        a Pływacy 20/(0+1)=20. <strong>Wygrywają więc <mark className="party-b">Biegacze</mark></strong> i otrzymują 1 mandat.
                      </li>
                      <li>
                        W trzeciej rundzie, Rowerzyści ponownie mają wynik 50/(1+1)=25, Biegacze otrzymują 30/(1+1)=15,
                        a Pływacy zostają przy 20/(0+1)=20.
                        Ponownie <strong>wygrywają więc <mark className="party-a">Rowerzyści</mark></strong> i otrzymują drugi mandat.
                      </li>
                      <li>
                        W czwartej rundzie, Rowerzyści mają wynik 50/(2+1)=16.67, Biegacze 30/(1+1)=15, a Pływacy 20/(0+1)=20.
                        Tym razem <strong>wygrywają <mark className="party-c">Pływacy</mark></strong> i otrzymują swój pierwszy i jedyny mandat.
                      </li>
                    </ol>
                  </p>
                  <p>
                    Po czterech rundach zakończyliśmy więc wybory z następującym wynikiem:
                    <mark className="party-a">2 mandaty dla Rowerzystów</mark>,{' '}
                    <mark className="party-b">1 dla Biegaczy</mark>{' '}
                    <mark className="party-c">i 1 dla Pływaków</mark>.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="row chapter chapter-3">
          <div className="col-xl-8">
            <h3>
              <span className="header-number">3.</span>
              Dlaczego metoda D'Hondta jest niebezpieczna?
            </h3>
            <p>
              Przyjrzyjmy się jednemu konkretnemu okręgowi wyborczemu,
              w którym do podziału jest <strong>7 mandatów</strong>.
              Rzeczywistym przykładem takiego okręgu jest Częstochowa.
            </p>
            <p>
              Załóżmy, że {getDemocraticCoalitionName(true)} wystartowała z trzech osobnych list (KO, Lewica i Trzecia Droga)
              i że poradziła sobie całkiem nieźle, i ostateczne wyniki w tym okręgu wyglądały tak:
              <ul>
                <li><strong>33%</strong> głosów dla PiS (Zjednoczonej Prawicy),</li>
                <li><strong>31%</strong> głosów dla Koalicji Obywatelskiej,</li>
                <li><strong>13%</strong> głosów dla Konfederacji,</li>
                <li><strong>12%</strong> głosów dla Trzeciej Drogi,</li>
                <li><strong>11%</strong> głosów dla Lewicy.</li>
              </ul>
              Na nikogo innego nie głosowano (lub żadna inna lista nie przekroczyła progu wyborczego).
            </p>
            <p>Zobaczmy jak rozdzieliły się mandaty przy takich wynikach:</p>
            <div className="row justify-content-center">
              <div className="col-xl-11 example-chart" >
                <ExampleChart
                  totalSeats={CONSTITUENCY_EXAMPLE_1_SEATS}
                  seatTickValues={[0, 3, 4, 7]}
                  seats={calculateSeats(
                    CONSTITUENCY_EXAMPLE_1_SCORES,
                    CONSTITUENCY_EXAMPLE_1_SCORES,
                    CONSTITUENCY_EXAMPLE_1_SEATS,
                    TOTAL_VOTES,
                  )}
                  scores={CONSTITUENCY_EXAMPLE_1_SCORES}
                  groupMapping={noBlocksGroupMapping(DEFAULT_PARTY_ORDER)}
                  adjustScores={false}
                  displayScores={true}
                  displayLegend={true}
                  showUndecidedVoters={true} />
              </div>
            </div>
            <p>
              <ul className="list-unstyled">
                <li>
                  <BadMessage>
                    <strong>PiS zdobył 3 mandaty</strong>, czyli 43% wszystkich, mimo że otrzymał <strong>tylko 33% głosów</strong>.
                  </BadMessage>
                </li>
                <li>
                  <BadMessage>
                    Konfederacja zdobyła 1 mandat. Razem z PiS mają więc już 57% wszystkich mandatów po otrzymaniu tylko 46% głosów.
                  </BadMessage>
                </li>
                <li>
                  <BadMessage>
                    <strong>{getDemocraticCoalitionName()} też zdobyła 3 mandaty</strong>, czyli 43% wszystkich, mimo że otrzymała <strong>54% głosów</strong>.
                  </BadMessage>
                </li>
                <li>
                  <BadMessage>
                    <strong>Lewica nie dostała żadnego mandatu</strong> mimo całkiem niezłego wyniku, więc głos 11% wyborców został zmarnowany.
                  </BadMessage>
                </li>
              </ul>
            </p>
            <Accordion className="col-12 mt-4 pt-2 invisible-accordion" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="extra-options">

                  <h4><FontAwesomeIcon icon={faHandPointRight} />&nbsp;Jak zdobyć kolejny mandat?</h4>
                </Accordion.Header>
                <Accordion.Body className="dhondt-example">
                  <p>
                    Może wyniki Konfederacji, Lewicy i Trzeciej Drogi zostały dobrane złośliwie? Sprawdźmy co by się stało
                    gdyby Trzecia Droga odebrała 1% głosów Konfederacji i uzyskała nad nią przewagę:
                  </p>
                  <div className="row justify-content-center">
                    <div className="col-xl-11 example-chart example-spoiler" >
                      <ExampleChart
                        totalSeats={CONSTITUENCY_EXAMPLE_1_SEATS}
                        seatTickValues={[0, 3, 4, 7]}
                        seats={calculateSeats(
                          CONSTITUENCY_EXAMPLE_1_2_SCORES,
                          CONSTITUENCY_EXAMPLE_1_2_SCORES,
                          CONSTITUENCY_EXAMPLE_1_SEATS,
                          TOTAL_VOTES,
                        )}
                        scores={CONSTITUENCY_EXAMPLE_1_2_SCORES}
                        groupMapping={noBlocksGroupMapping(DEFAULT_PARTY_ORDER)}
                        adjustScores={false}
                        showUndecidedVoters={true} />
                    </div>
                  </div>
                  <p>
                    Nie zmieniło się nic. Dopiero gdyby zarówno Trzecia Droga, jak i Lewica zdobyły więcej głosów niż Konfederacja,{' '}
                    ta ostatnia straciłaby swój mandat:
                  </p>
                  <div className="row justify-content-center">
                    <div className="col-xl-11 example-chart example-spoiler" >
                      <ExampleChart
                        totalSeats={CONSTITUENCY_EXAMPLE_1_SEATS}
                        seatTickValues={[0, 3, 4, 7]}
                        seats={calculateSeats(
                          CONSTITUENCY_EXAMPLE_1_3_SCORES,
                          CONSTITUENCY_EXAMPLE_1_3_SCORES,
                          CONSTITUENCY_EXAMPLE_1_SEATS,
                          TOTAL_VOTES,
                        )}
                        scores={CONSTITUENCY_EXAMPLE_1_3_SCORES}
                        groupMapping={noBlocksGroupMapping(DEFAULT_PARTY_ORDER)}
                        adjustScores={false}
                        showUndecidedVoters={true} />
                    </div>
                  </div>
                  <p>Podobna sytuacja występuje w innych małych okręgach. <strong>{getDemocraticCoalitionName()} straci mandaty w wielu okręgach
                    jeśli tylko którakolwiek z opozycyjnych partii uzyska tam wynik gorszy od Konfederacji.</strong>{' '}
                    Im mniejszy okręg, tym większe ryzyko, że głosy na opozycję zostaną w ten sposób zmarnowane.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="row chapter chapter-4">
          <div className="col-xl-8">
            <h3>
              <span className="header-number">4.</span>
              Czy da się zwiększyć szanse?
            </h3>
            <p>
              Wróćmy do naszych oryginalnych wyników, ale załóżmy, że Koalicja Obywatelska i Lewica wystartowały ze wspólnej listy wyborczej.
              Poparcie się nie zmieniło, i wspólna lista dostała <strong>42%</strong> głosów: odpowiednio 31% dla KO i 11% dla Lewicy.
            </p>
            <p>Jaki jest efekt tym razem?</p>
            <div className="row justify-content-center">
              <div className="col-xl-11 example-chart" >
                <ExampleChart
                  totalSeats={CONSTITUENCY_EXAMPLE_2_SEATS}
                  seatTickValues={[0, 3, 4, 7]}
                  seats={calculateSeats(
                    getGroupScores(CONSTITUENCY_EXAMPLE_2_SCORES, CONSTITUENCY_EXAMPLE_2_GROUP_MAPPING),
                    getGroupScores(CONSTITUENCY_EXAMPLE_2_SCORES, CONSTITUENCY_EXAMPLE_2_GROUP_MAPPING),
                    CONSTITUENCY_EXAMPLE_2_SEATS,
                    TOTAL_VOTES,
                  )}
                  scores={CONSTITUENCY_EXAMPLE_2_SCORES}
                  groupMapping={CONSTITUENCY_EXAMPLE_2_GROUP_MAPPING}
                  adjustScores={false}
                  showUndecidedVoters={true}
                />
              </div>
            </div>
            <p>
              <ul className="list-unstyled">
                <li>
                  <GoodMessage>
                    <strong>PiS traci jeden mandat</strong> i teraz zdobywając 33% głosów, otrzymuje tylko 28% mandatów.
                  </GoodMessage>
                </li>
                <li>
                  <GoodMessage>
                    <strong>{getDemocraticCoalitionName()} zdobywa o jeden mandat więcej</strong>, czyli 57% mandatów przy 54% otrzymanych głosów.
                  </GoodMessage>
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="row chapter chapter-5">
          <div className="col-xl-8">
            <h3>
              <span className="header-number">5.</span>
              Ale przecież wyborcy nie zagłosują na wspólną listę!
            </h3>
            <p>Wiele osób twierdzi, że wyborcy mogą zniechęcić się w przypadku wspólnej listy wielu partii.
              Co prawda zagrożenie to można zmniejszyć poprzez odpowiednią kampanię &mdash; na przykład tłumacząc, że jest to tylko
              sojusz taktyczny ze względu na ordynację wyborczą &mdash; ale nie da się go całkowicie wyeliminować.
            </p>
            <p>
              Załóżmy więc, że część wyborców zrezygnowała z głosowania w przypadku wspólnej listy
              i około <strong>10% wyborców KO i Lewicy zostało w domu</strong> w dniu wyborów. Co dziesiąty zniechęcony
              wyborca to całkiem dużo.
            </p>
            <p>
              Po przeliczeniu, nowe wyniki wyborów w naszym okręgu to
              (w przybliżeniu, zaokrąglone do pełnych procentów dla przejrzystości):
              <ul>
                <li><strong>35%</strong> zamiast <strong>33%</strong> dla PiS,</li>
                <li><strong>38%</strong> zamiast <strong>42%</strong> dla wspólnej listy KO i Lewicy,</li>
                <li><strong>14%</strong> zamiast <strong>13%</strong> dla Konfederacji,</li>
                <li><strong>13%</strong> zamiast <strong>12%</strong> dla Trzeciej Drogi.</li>
              </ul>
            </p>
            <p>Efekt?</p>
            <div className="row justify-content-center">
              <div className="col-xl-11 example-chart" >
                <ExampleChart
                  totalSeats={CONSTITUENCY_EXAMPLE_3_SEATS}
                  seatTickValues={[0, 3, 4, 7]}
                  seats={calculateSeats(
                    getGroupScores(CONSTITUENCY_EXAMPLE_3_SCORES, CONSTITUENCY_EXAMPLE_3_GROUP_MAPPING),
                    getGroupScores(CONSTITUENCY_EXAMPLE_3_SCORES, CONSTITUENCY_EXAMPLE_3_GROUP_MAPPING),
                    CONSTITUENCY_EXAMPLE_3_SEATS,
                    TOTAL_VOTES,
                  )}
                  scores={CONSTITUENCY_EXAMPLE_3_SCORES}
                  groupMapping={CONSTITUENCY_EXAMPLE_3_GROUP_MAPPING}
                  adjustScores={false}
                  showUndecidedVoters={true} />
              </div>
            </div>
            <p>
              <ul className="list-unstyled">
                <li>
                  <GoodMessage>
                    <strong>PiS nadal zdobywa tylko 2 mandaty</strong> mimo 35% głosów.
                  </GoodMessage>
                </li>
                <li>
                  <GoodMessage>
                    <strong>{getDemocraticCoalitionName()} nadal zdobywa 4 mandaty</strong>, czyli 57% wszystkich mandatów, mimo otrzymanych tylko 51% głosów.
                  </GoodMessage>
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="row chapter chapter-6">
          <div className="col-xl-8">
            <h3>
              <span className="header-number">6.</span>
              Czy można jeszcze lepiej?
            </h3>
            <p>Rozważmy wspólną listę całej opozycji i ponownie utratę nawet 10% zwolenników opozycji.</p>
            <p>W przybliżeniu otrzymamy:
              <ul>
                <li><strong>36%</strong> zamiast <strong>33%</strong> dla PiS,</li>
                <li><strong>49%</strong> zamiast <strong>54%</strong> dla wspólnej listy opozycji,</li>
                <li><strong>15%</strong> zamiast <strong>13%</strong> dla Konfederacji.</li>
              </ul>
            </p>
            <p>Jak wygląda podział mandatów tym razem?</p>
            <div className="row justify-content-center">
              <div className="col-xl-12 example-chart" >
                <ExampleChart
                  totalSeats={CONSTITUENCY_EXAMPLE_4_SEATS}
                  seatTickValues={[0, 3, 4, 7]}
                  seats={calculateSeats(
                    getGroupScores(CONSTITUENCY_EXAMPLE_4_SCORES, CONSTITUENCY_EXAMPLE_4_GROUP_MAPPING),
                    getGroupScores(CONSTITUENCY_EXAMPLE_4_SCORES, CONSTITUENCY_EXAMPLE_4_GROUP_MAPPING),
                    CONSTITUENCY_EXAMPLE_4_SEATS,
                    TOTAL_VOTES,
                  )}
                  scores={CONSTITUENCY_EXAMPLE_4_SCORES}
                  groupMapping={CONSTITUENCY_EXAMPLE_4_GROUP_MAPPING}
                  adjustScores={false}
                  showUndecidedVoters={true} />
              </div>
            </div>
            <p>
              <ul className="list-unstyled">
                <li>
                  <GoodMessage>
                    <strong>{getDemocraticCoalitionName()} nadal zdobywa 4 mandaty</strong>,
                    czyli 57% wszystkich mandatów, mimo otrzymanych tylko 49% głosów.
                  </GoodMessage>
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="row chapter chapter-7">
          <div className="col-xl-8">
            <h3>
              <span className="header-number">7.</span>
              Co jeśli to jeszcze bardziej zniechęci wyborców?
            </h3>
            <p>Załóżmy teraz, że wyborcy opozycji naprawdę nie lubią idei wspólnej listy
              i w ramach protestu co trzeci zostanie w domu w dniu wyborów. Albo równoważnie:
              część zostanie, a zirytowana część nawet zagłosuje na PiS lub Konfederację.</p>
            <p>W przybliżeniu, jeśli co trzeci wyborca opozycji nie zagłosuje:
              <ul>
                <li><strong>46%</strong> zamiast <strong>33% </strong>dla PiS,</li>
                <li><strong>18%</strong> zamiast <strong>13%</strong> dla Konfederacji,</li>
                <li><strong>36%</strong> zamiast <strong>54%</strong> dla całej opozycji.</li>
              </ul>
            </p>
            <p>
              W rezultacie otrzymujemy dokładnie taki podział mandatów jak w przypadku trzech osobnych list
              opozycji:
            </p>
            <div className="row justify-content-center">
              <div className="col-xl-11 example-chart" >
                <ExampleChart
                  totalSeats={CONSTITUENCY_EXAMPLE_5_SEATS}
                  seatTickValues={[0, 3, 4, 7]}
                  seats={calculateSeats(
                    getGroupScores(CONSTITUENCY_EXAMPLE_5_SCORES, CONSTITUENCY_EXAMPLE_5_GROUP_MAPPING),
                    getGroupScores(CONSTITUENCY_EXAMPLE_5_SCORES, CONSTITUENCY_EXAMPLE_5_GROUP_MAPPING),
                    CONSTITUENCY_EXAMPLE_5_SEATS,
                    TOTAL_VOTES,
                  )}
                  scores={CONSTITUENCY_EXAMPLE_5_SCORES}
                  groupMapping={CONSTITUENCY_EXAMPLE_5_GROUP_MAPPING}
                  adjustScores={false}
                  showUndecidedVoters={true} />
              </div>
            </div>
            <p>
              <ul className="list-unstyled">
                <li>
                  <GoodMessage><strong>Pomimo ogromnej utraty elektoratu, {getDemocraticCoalitionName(true)} nadal zdobywa 3 mandaty</strong>,
                    czyli 43% wszystkich mandatów.
                    Dokładnie tyle ile zdobyła wcześniej otrzymując 54% głosów w przypadku
                    startu z trzech osobnych list.
                  </GoodMessage>
                </li>
                <li>
                  <GoodMessage><strong>PiS nadal ma 3 mandaty</strong>, mimo wzrostu poparcia z 33% do 46%.</GoodMessage>
                </li>
                <li>
                  <GoodMessage>Konfederacja nadal ma 1 mandat, mimo wzrostu poparcia z 13% do 18%.</GoodMessage>
                </li>
              </ul>
            </p>
            <p>I jeszcze raz, dla porównania, konfiguracja od której zaczęliśmy:</p>
            <div className="row justify-content-center">
              <div className="col-xl-11 example-chart" >
                <ExampleChart
                  totalSeats={CONSTITUENCY_EXAMPLE_1_SEATS}
                  seatTickValues={[0, 3, 4, 7]}
                  seats={calculateSeats(
                    CONSTITUENCY_EXAMPLE_1_SCORES,
                    CONSTITUENCY_EXAMPLE_1_SCORES,
                    CONSTITUENCY_EXAMPLE_1_SEATS,
                    TOTAL_VOTES,
                  )}
                  scores={CONSTITUENCY_EXAMPLE_1_SCORES}
                  groupMapping={noBlocksGroupMapping(DEFAULT_PARTY_ORDER)}
                  adjustScores={false}
                  showUndecidedVoters={true} />
              </div>
            </div>
          </div>
          <div className="col-xl-4 d-xl-block d-none">
            <ExplanationSharingButtons />
          </div>
        </div>
        <div className="row chapter chapter-8">
          <div className="col-xl-8">
            <h3>
              <span className="header-number">8.</span>
              Podsumowanie
            </h3>
            <p>Przedstawiony powyżej przykład dotyczy tylko jednego okręgu, ale podobna rozgrywka ma miejsce
              w każdym z 41 okręgów wyborczych. Oczywiście, im większy okręg, tym mniej
              niebezpieczna staje się metoda D'Hondta. Ale nawet w 20-mandatowej Warszawie mniej popularne
              partie dużo ryzykują startując z osobnych list.</p>

            <p>
              W tym miejscu warto przypomnieć, że ryzyko nie ogranicza się tylko do niekorzystnego podziału mandatów.
              W wyborach do Sejmu w 2015 roku koalicja Zjednoczonej Lewicy otrzymała 7.55% głosów i nie przekroczyła
              progu wyborczego. Utracone w ten sposób głosy dały zwycięstwo PiS.
            </p>

            <p>
              <strong>Wspólna lista wyborcza nie oznacza wspólnego programu</strong>.
              Jest to <strong>taktyczny sojusz chroniący przed efektami metody D'Hondta</strong> w małych okręgach
              i usuwający ryzyko nieprzekroczenia progu wyborczego. Stanowi
              zabezpieczenie przed ponowną wygraną PiS i przed utratą mandatów tylko ze względu
              na niekorzystną ordynację wyborczą.
            </p>

            <p><strong>Zjednoczona Prawica stosuje taktykę wspólnej listy już od 2015 roku</strong>, kiedy to
              Solidarna Polska i Polska Razem wystartywały z listy PiS. W 2022 roku pojawił się nawet pomysł{' '}
              <a href="https://www.gazetaprawna.pl/wiadomosci/kraj/artykuly/8515960,zmiany-w-ordynacji-sejm-kaczynski-pis-mandaty-sceptycy-prezydent.html">zwiększenia liczby okręgów do 100</a>,{' '}
              co po wyborach jeszcze bardziej wzmocniłoby przewagę
              partii o dużym poparciu.
            </p>

            <p>Chcesz sam się przekonać jak rozkładają się mandaty we wszystkich okręgach?
              Albo przetestować różne konfiguracje list wyborczych?
            </p>
          </div>
          <div className="col-xl-8 d-flex flex-column align-items-center">
            <TryCalculatorButton />
          </div>
          <div className="col-xl-8 explanation-sharing pt-4 mb-0 d-flex flex-column align-items-center">
            <p>Uważasz, że wspólna lista to dobry pomysł? Pomóż przekonać innych i udostępnij:
            </p>
            <ShareButtons url={document.location} />
          </div>
        </div>
      </div >
    );
  }
}

export class ExampleChart extends React.Component {
  getName(key) {
    return PARTY_DATA.hasOwnProperty(key)
      ? PARTY_DATA[key].name
      : GROUP_DATA[key].nameLong || GROUP_DATA[key].name;
  }

  getSortOrder(key) {
    return PARTY_DATA.hasOwnProperty(key) ? PARTY_DATA[key].sortOrder : GROUP_DATA[key].sortOrder;
  }

  getColor(key) {
    return PARTY_DATA.hasOwnProperty(key) ? PARTY_DATA[key].color : GROUP_DATA[key].color;
  }

  getAccentColor(key) {
    return PARTY_DATA.hasOwnProperty(key) ? PARTY_DATA[key].accentColor : GROUP_DATA[key].accolorcentColor;
  }

  getPartyClass(key) {
    return 'party-' + key.description;
  }

  getKeyNames(seats) {
    let keys = Object.getOwnPropertySymbols(seats)
      .sort(
        (a, b) => this.getSortOrder(a) - this.getSortOrder(b)
      )
      .map(key => this.getName(key));
    return this.props.showUndecidedVoters ? keys : getKnownKeys(keys);
  }

  getKeys(seats) {
    let keys = Object.getOwnPropertySymbols(seats)
      .sort(
        (a, b) => this.getSortOrder(a) - this.getSortOrder(b)
      );
    return this.props.showUndecidedVoters ? keys : getKnownKeys(keys);
  }

  getSortOrder(key) {
    return PARTY_DATA.hasOwnProperty(key) ? PARTY_DATA[key].sortOrder : GROUP_DATA[key].sortOrder;
  }

  buildBarData(source, title = "") {
    let keys = Object.getOwnPropertySymbols(source)
      .sort(
        (a, b) => this.getSortOrder(a) - this.getSortOrder(b)
      );
    let datasets = keys.map(key => {
      return {
        label: this.getName(key),
        data: [roundToDecimal(source[key])],
        backgroundColor: this.getColor(key),
        borderColor: this.getAccentColor(key),
      }
    });
    return {
      labels: [title],
      datasets: datasets,
    };
  }

  render() {
    const seatData = this.buildBarData(this.props.seats, "Mandaty");
    const scores =
      this.props.showUndecidedVoters ? this.props.scores : getNormalisedKnownScores(this.props.scores);
    const scoreData =
      this.buildBarData(
        getGroupScores(scores, this.props.groupMapping),
        "Poparcie",
      );
    const keys = this.getKeys(this.props.seats);
    return (
      <div>
        {!this.props.hideScores &&
          <div className="score-chart">
            <BarChart
              data={scoreData}
              axisMin={0}
              axisMax={100}
              showLegend={false}
              marker={50}
              tickValues={[0, 25, 50, 75, 100]}
              hideXTicks={this.props.hideXTicks}
              showPercentage={true}
            />
          </div>
        }
        {!this.props.hideSeats &&
          <div className="seat-chart">
            <BarChart
              data={seatData}
              axisMin={0}
              axisMax={this.props.totalSeats}
              showLegend={false}
              hideLabels={this.props.hideLabels}
              marker={this.props.totalSeats / 2}
              tickValues={this.props.seatTickValues || [0, this.props.totalSeats / 2, this.props.totalSeats]}
              hideXTicks={this.props.hideXTicks}
            />
          </div>
        }
        {!this.props.hideLegend &&
          <ul className="legend list-inline mb-0">
            {keys.map(key =>
              <li className="list-inline-item" key={key.description}>
                <span className={`${this.getPartyClass(key)} p-icon`}>
                  <FontAwesomeIcon icon={faSquare} />
                </span>
                <span className="p-name text-truncate">{this.getName(key)}</span>
                <span className="p-value">&nbsp;({this.props.seats[key]})</span>
              </li>
            )}
          </ul>
        }
      </div>
    );
  }
}