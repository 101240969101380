import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip as RBTooltip, OverlayTrigger } from "react-bootstrap";
import {
  faSquareXmark,
  faSquareCheck,
  faSquare,
  faMagnifyingGlassChart,
  faCircleQuestion,
  faPersonBooth,
  faCaretDown,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  calculateSeats,
  totalForGroup,
  roundToDecimal,
  floorToDecimal,
  getKnownScores,
  getNormalisedKnownScores,
  adjustConstituencyScores,
  calculateSeatsInConstituencies,
  getGroupScores,
  findWinningResult,
} from './calc.js';
import {
  TOTAL_SEATS,
  PARTY_DATA,
  GROUP_DATA,
  isGovernment,
  isPis,
  getKnownKeys,
  CONSTITUTIONAL_MAJORITY,
  VETO_MAJORITY,
  SIMPLE_MAJORITY,
  CONSTITUENCIES,
  Parties,
  isOpposition,
  isOther,
  isKonfederacja,
  CONSTITUENCY_MAP,
} from './config.js';
import {
  getDemocraticCoalitionName,
  getSeatsInPolish
} from './localisation.js';
import { singleBlockGroupMapping } from "./transform.js";
import { isInternalVersion } from "./router.js";
import _, { floor } from "lodash";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import { computeWastedVotes, roundWastedVotes, totalVotesPerParty } from "./wasted_votes.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  annotationPlugin,
);

export class Summary extends React.Component {
  render() {
    const totalVotes = totalVotesPerParty(this.props.scores, this.props.groupMapping, this.props.adjustScores);
    const seats = calculateSeatsInConstituencies(
      this.props.scores,
      this.props.groupMapping,
      this.props.adjustScores,
    );
    const wastedVotes = computeWastedVotes(this.props.scores, this.props.groupMapping, this.props.adjustScores);
    const govSeats = totalForGroup(seats, isGovernment);
    const pisSeats = totalForGroup(seats, isPis);
    const konfederacjaSeats = govSeats - pisSeats;
    const oppositionSeats = TOTAL_SEATS - govSeats;

    // CSS transitions rely on each message being defined separately
    return (
      <div className="container-fluid p-0 ps-2 mt-3">
        <div className="row">
          <div className="col">
            <SeatCards
              oppositionSeats={oppositionSeats}
              pisSeats={pisSeats}
              konfederacjaSeats={konfederacjaSeats} />
          </div>
        </div>
        <div className="row">
          <div className="col p-1 mb-2">
            <div className="card">
              <div className="card-body">
                <SeatChart
                  seats={seats}
                  scores={this.props.scores}
                  groupMapping={this.props.groupMapping}
                  adjustScores={this.props.adjustScores}
                  displayConstituencies={this.props.displayConstituencies}
                  displayScores={false}
                  displayLegend={false}
                  hideLabels={true}
                  showUndecidedVoters={this.props.showUndecidedVoters} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col p-0 p-1 mb-2 outcomes">
            <div className="card">
              <div className="card-header">
                Jaki będzie efekt?
              </div>
              <div className="card-body">
                <Messages
                  oppositionSeats={oppositionSeats}
                  govSeats={govSeats}
                  pisSeats={pisSeats} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col p-1 mb-2">
            <BetterResult
              scores={this.props.scores}
              adjustScores={this.props.adjustScores}
              oppositionSeats={oppositionSeats} />
          </div>
        </div>
        <div className="row">
          <div className="col p-1 mb-1">
            <WastedVotesResult
              wastedVotes={wastedVotes}
              totalVotes={totalVotes} />
          </div>
        </div>
      </div>
    );
  }
}

class Messages extends React.Component {
  getMessages() {
    let messages = [];
    if (this.props.govSeats == this.props.oppositionSeats) {
      messages.push(
        <BadMessage msg={`${getDemocraticCoalitionName()} remisuje z PiS i Konfederacją.`} />
      );
    }
    if (this.props.pisSeats >= SIMPLE_MAJORITY) {
      messages.push(
        <BadMessage msg='PiS może rządzić samodzielnie.' />
      );
    }
    if (this.props.pisSeats < SIMPLE_MAJORITY && this.props.govSeats >= SIMPLE_MAJORITY) {
      messages.push(
        <BadMessage msg='PiS może rządzić w koalicji z Konfederacją.' />
      );
    }
    if (this.props.pisSeats >= VETO_MAJORITY) {
      messages.push(

        <BadMessage msg='PiS może samodzielnie odrzucić weto prezydenta (wymaga 276&nbsp;mandatów).' />
      );
    }
    if (this.props.pisSeats < VETO_MAJORITY && this.props.govSeats >= VETO_MAJORITY) {
      messages.push(
        <BadMessage msg='PiS może odrzucić weto prezydenta z pomocą Konfederacji (wymaga 276&nbsp;mandatów).' />
      );
    }
    if (this.props.pisSeats >= CONSTITUTIONAL_MAJORITY) {
      messages.push(
        <BadMessage msg='PiS może samodzielnie zmienić konstytucję (wymaga 307&nbsp;mandatów).' />
      );
    }
    if (this.props.pisSeats < CONSTITUTIONAL_MAJORITY && this.props.govSeats >= CONSTITUTIONAL_MAJORITY) {
      messages.push(
        <BadMessage msg='PiS może zmienić konstytucję z pomocą Konfederacji (wymaga 307&nbsp;mandatów).' />
      );
    }
    if (this.props.govSeats >= SIMPLE_MAJORITY) {
      messages.push(
        <BadMessage msg={`PiS i Konfederacja mają razem ${this.props.govSeats} ${getSeatsInPolish(this.props.govSeats)}.`} />
      );
    }

    if (this.props.oppositionSeats >= SIMPLE_MAJORITY) {
      messages.push(
        <GoodMessage msg={`${getDemocraticCoalitionName()} wygrywa i może stworzyć rząd.`} />
      );
    }
    if (this.props.oppositionSeats >= VETO_MAJORITY) {
      messages.push(
        <GoodMessage msg={`${getDemocraticCoalitionName()} może odrzucić weto prezydenta (wymaga 276\u00A0mandatów).`} />
      );
    }
    if (this.props.oppositionSeats >= CONSTITUTIONAL_MAJORITY) {
      messages.push(
        <GoodMessage msg={`${getDemocraticCoalitionName()} może zmienić konstytucję (wymaga 307\u00A0mandatów).`} />
      );
    }
    return messages;
  }

  render() {
    return (
      <ul className="list-unstyled">
        {this.getMessages().map(
          (msg, id) =>
            <li key={id}>{msg}</li>
        )}
      </ul>
    );
  }
}

export class WastedVotesResult extends React.Component {
  selectWastedVotes(wastedVotes) {
    // return only those that are greater than 0 and not pozostali
    return Object.getOwnPropertySymbols(wastedVotes.votes)
      .filter(key => wastedVotes.votes[key] > 0 && !isOther(key))
      .reduce((obj, key) => {
        obj[key] = [wastedVotes.votes[key], wastedVotes.constituencies[key]];
        return obj;
      }, {});
  }

  getNameAccusative(key) {
    return PARTY_DATA.hasOwnProperty(key)
      ? PARTY_DATA[key].nameAccusative
      : GROUP_DATA[key].nameAccusative;
  }

  render() {
    let wastedVotes = this.selectWastedVotes(this.props.wastedVotes);
    return <div className="wasted-outcomes">
      {Object.getOwnPropertySymbols(wastedVotes).length > 0 &&
        <div className="card p-0">
          <div className="card-header">
            Niektóre głosy się marnują!{' '}
            <OverlayTrigger
              placement="top"
              overlay={
                <RBTooltip id="tooltip-wastedVotes">
                  <p>Szacowana liczba oddanych głosów, które całkowicie się zmarnują, czyli oddanych na partie{' '}
                    i listy zdobywające <strong>zero mandatów</strong> w danym okręgu.</p>
                  <p>Obliczana jako suma ważnych głosów oddanych w indywidualnych okręgach na poszczególne partie,
                    przy założeniu frekwencji identycznej do tej z wyborów w 2023 roku. Zaokrąglona w dół do najbliższego tysiąca.
                  </p>
                  <p>
                    Źródło danych: https://wybory.gov.pl/sejmsenat2023/pl/dane_w_arkuszach
                  </p>
                </RBTooltip>
              }
            >
              <Button variant="link" className="p-0 tooltip-icon"><FontAwesomeIcon icon={faCircleQuestion} /></Button>
            </OverlayTrigger>
          </div >
          <div className="card-body">
            <ul className="list-unstyled list-inline mb-0">
              {Object.getOwnPropertySymbols(wastedVotes).map((key, index) =>
                <li key={index}>
                  <span className="wasted-description">
                    <span className={`party-${key.description} p-icon`}>
                      <FontAwesomeIcon icon={faPersonBooth} />
                    </span>{' '}
                    <strong>{roundWastedVotes(wastedVotes[key][0]).toLocaleString('pl-PL')}</strong> głosów{' '}
                    na{' '}
                    <strong>{this.getNameAccusative(key)}</strong>{' '}
                    ({(wastedVotes[key][0] / this.props.totalVotes[key] * 100).toFixed(1)}% poparcia)
                  </span>
                  {(wastedVotes[key][1].length === CONSTITUENCIES.length)
                    ?
                    <span className="wasted-constituencies">Zero mandatów we wszystkich okręgach.</span>
                    :
                    <span className={`wasted-constituencies ${(wastedVotes[key][1].length === 1) ? 'single-constituency' : ''}`}>
                      Zero mandatów w {wastedVotes[key][1].length}{' '}
                      {(wastedVotes[key][1].length === 1) ? 'okręgu' : 'okręgach'}:{' '}
                      {wastedVotes[key][1].sort().join(', ')}.
                    </span>
                  }
                </li>
              )}
            </ul>
          </div>
        </div >
      }
    </div >
  }
}

class BetterResult extends React.Component {
  render() {
    const winningScores = findWinningResult(
      this.props.scores,
      SIMPLE_MAJORITY,
      this.props.adjustScores
    );
    const betterResultScores = findWinningResult(
      this.props.scores,
      this.props.oppositionSeats,
      this.props.adjustScores
    )
    const singleBlockSeats = calculateSeatsInConstituencies(
      this.props.scores,
      singleBlockGroupMapping(),
      this.props.adjustScores,
    );
    const normalisedScores = getNormalisedKnownScores(this.props.scores);
    const oppositionSingleBlockSeats = totalForGroup(singleBlockSeats, isOpposition);
    const oppositionScore = totalForGroup(normalisedScores, isOpposition);
    const winningOppositionScore = totalForGroup(winningScores, isOpposition);
    const winningScoreTurnoutLoss = (1 - (winningOppositionScore / oppositionScore)) * 100;
    const betterOppositionScore = totalForGroup(betterResultScores, isOpposition);
    const betterScoreTurnoutLoss = (1 - (betterOppositionScore / oppositionScore)) * 100;
    const canWinWithLess = (oppositionScore - winningOppositionScore) > 0.1;
    const canGetSameSeatsWithLess = (oppositionScore - betterOppositionScore) > 0.1;

    let messages = [];
    if (oppositionSingleBlockSeats > this.props.oppositionSeats) {
      messages.push(
        <span>...zdobyłaby
          o <strong>{oppositionSingleBlockSeats - this.props.oppositionSeats}</strong>{' '}
          {getSeatsInPolish(oppositionSingleBlockSeats - this.props.oppositionSeats)} więcej{' '}
          przy identycznym poparciu (razem {oppositionSingleBlockSeats}).
        </span>
      )
    }
    if (this.props.oppositionSeats < SIMPLE_MAJORITY && canWinWithLess) {
      messages.push(
        <span>
          ...wygrałaby nawet tracąc <strong>{floorToDecimal(winningScoreTurnoutLoss)}%</strong> poparcia{' '}
          ({roundToDecimal(winningOppositionScore)}%{' '}
          zamiast {roundToDecimal(oppositionScore)}%).
        </span>
      );
    }
    if (canGetSameSeatsWithLess) {
      messages.push(
        <span>...uzyskałaby tyle samo mandatów nawet tracąc <strong>{floorToDecimal(betterScoreTurnoutLoss)}%</strong> poparcia{' '}
          ({roundToDecimal(betterOppositionScore)}%{' '}
          zamiast {roundToDecimal(oppositionScore)}%).
        </span>
      )
    }

    return (
      <div className="hypotheticals">
        {messages.length > 0 &&
          <div className="card p-0">
            <div className="card-header">
              Startując z jednej wspólnej listy, {getDemocraticCoalitionName(true)}...
            </div>
            <div className="card-body">
              <ul className="list-inline list-unstyled mb-2">
                {messages.map((msg, id) =>
                  <li className="list-inline-item" key={id}>{msg}</li>
                )}
              </ul>
            </div>
          </div>
        }
      </div>
    );
  }
}

export class GoodMessage extends React.Component {
  render() {
    return (
      <p className="good-msg">
        <span className="icon">
          <FontAwesomeIcon icon={faSquareCheck} />
        </span>
        {this.props.msg}{this.props.children}
      </p>
    );
  }
}

export class BadMessage extends React.Component {
  render() {
    return (
      <p className="bad-msg">
        <span className="icon">
          <FontAwesomeIcon icon={faSquareXmark} />
        </span>
        {this.props.msg}{this.props.children}
      </p>
    );
  }
}

class SeatCards extends React.Component {
  render() {
    return (
      <div className="container-fluid p-0 m-0 mb-2 seat-cards">
        <div className="row">
          <div className="col-4 p-1">
            <div className="card seats">
              <div className="card-header seats-header party-opozycja text-truncate">{getDemocraticCoalitionName()}</div>
              <div className="card-body">
                <p
                  className={`card-text value ${this.props.oppositionSeats >= SIMPLE_MAJORITY && "good-msg"}`}>
                  {this.props.oppositionSeats}
                </p>
              </div>
            </div>
          </div>
          <div className="col-4 p-1">
            <div className="card seats">
              <div className="card-header seats-header party-pis text-truncate">
                {PARTY_DATA[Parties.PIS].name}
              </div>
              <div className="card-body">
                <p className={`card-text value ${(this.props.pisSeats >= SIMPLE_MAJORITY) && "bad-msg"}`}>
                  {this.props.pisSeats}
                </p>
              </div>
            </div>
          </div>
          <div className="col-4 p-1">
            <div className="card seats">
              <div className="card-header seats-header party-konfederacja text-truncate">
                {PARTY_DATA[Parties.KONFEDERACJA].name}
              </div>
              <div className="card-body">
                <p className="card-text value">{this.props.konfederacjaSeats}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


class SmallSeatCards extends React.Component {
  render() {
    return (
      <div className="container-fluid p-0 m-0 mt-2 mb-1 seat-cards small-seat-cards">
        <div className="row g-0">
          <div className="col-4 col-xl-12">
            <div className="card mx-1">
              <div className="row g-0">
                <div className="col-xl-6 card-header party-opozycja text-truncate">
                  {getDemocraticCoalitionName()}
                </div>
                <div className="col-xl-6 card-body">
                  <p
                    className={`card-text ${this.props.oppositionSeats >= SIMPLE_MAJORITY && "good-msg"}`}>
                    {this.props.oppositionSeats}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 col-xl-12">
            <div className="card mx-1">
              <div className="row g-0">
                <div className="col-xl-6 card-header party-pis text-truncate">
                  {PARTY_DATA[Parties.PIS].name}
                </div>
                <div className="col-xl-6 card-body">
                  <p className={`card-text ${(this.props.pisSeats >= SIMPLE_MAJORITY) && "bad-msg"}`}>
                    {this.props.pisSeats}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 col-xl-12">
            <div className="card mx-1">
              <div className="row g-0">
                <div className="col-xl-6 card-header party-konfederacja text-truncate">
                  {PARTY_DATA[Parties.KONFEDERACJA].name}
                </div>
                <div className="col-xl-6 card-body">
                  <p className="card-text">{this.props.konfederacjaSeats}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class Result extends React.Component {
  render() {
    const seats = calculateSeatsInConstituencies(
      this.props.scores,
      this.props.groupMapping,
      this.props.adjustScores,
    );
    const govSeats = totalForGroup(seats, isGovernment);
    const pisSeats = totalForGroup(seats, isPis);
    const konfederacjaSeats = govSeats - pisSeats;
    const oppositionSeats = TOTAL_SEATS - govSeats;

    return (
      <div className={`row results pt-3 pb-4 g-0 ${isInternalVersion() ? 'results-export' : ''}`} id={this.props.elementId}>
        <div className="col-xl-4 pt-2">
          <h3>
            <FontAwesomeIcon icon={faMagnifyingGlassChart} className="header-icon" />
            {this.props.title}
          </h3>
          <SeatCards
            oppositionSeats={oppositionSeats}
            pisSeats={pisSeats}
            konfederacjaSeats={konfederacjaSeats}
          />
          {!isInternalVersion() &&
            <div className="messages-details pt-1">
              <Messages
                pisSeats={pisSeats}
                govSeats={govSeats}
                oppositionSeats={oppositionSeats} />
            </div>
          }
        </div>
        <div className="col-xl-8 px-0 px-md-2">
          <SeatChart
            seats={seats}
            scores={this.props.scores}
            groupMapping={this.props.groupMapping}
            adjustScores={this.props.adjustScores}
            displayConstituencies={this.props.displayConstituencies}
            displayScores={true}
            displayLegend={true}
            showUndecidedVoters={this.props.showUndecidedVoters} />
        </div>
      </div>
    );
  }
}

class SeatChart extends React.Component {
  getName(key) {
    return PARTY_DATA.hasOwnProperty(key)
      ? PARTY_DATA[key].name
      : GROUP_DATA[key].nameLong || GROUP_DATA[key].name;
  }

  getSortOrder(key) {
    return PARTY_DATA.hasOwnProperty(key) ? PARTY_DATA[key].sortOrder : GROUP_DATA[key].sortOrder;
  }

  getColor(key) {
    return PARTY_DATA.hasOwnProperty(key) ? PARTY_DATA[key].color : GROUP_DATA[key].color;
  }

  getAccentColor(key) {
    return PARTY_DATA.hasOwnProperty(key) ? PARTY_DATA[key].accentColor : GROUP_DATA[key].accolorcentColor;
  }

  getPartyClass(key) {
    return 'party-' + key.description;
  }

  getKeyNames(seats) {
    let keys = Object.getOwnPropertySymbols(seats)
      .sort(
        (a, b) => this.getSortOrder(a) - this.getSortOrder(b)
      )
      .map(key => this.getName(key));
    return this.props.showUndecidedVoters ? keys : getKnownKeys(keys);
  }

  getKeys(seats) {
    let keys = Object.getOwnPropertySymbols(seats)
      .sort(
        (a, b) => this.getSortOrder(a) - this.getSortOrder(b)
      );
    return this.props.showUndecidedVoters ? keys : getKnownKeys(keys);
  }

  getSortOrder(key) {
    return PARTY_DATA.hasOwnProperty(key) ? PARTY_DATA[key].sortOrder : GROUP_DATA[key].sortOrder;
  }

  buildBarData(source, title = "") {
    let keys = Object.getOwnPropertySymbols(source)
      .sort(
        (a, b) => this.getSortOrder(a) - this.getSortOrder(b)
      );
    let datasets = keys.map(key => {
      return {
        label: this.getName(key),
        data: [roundToDecimal(source[key])],
        backgroundColor: this.getColor(key),
        borderColor: this.getAccentColor(key),
      }
    });
    return {
      labels: [title],
      datasets: datasets,
    };
  }

  buildConstituencyData(scores, groupMapping) {
    const groupScores = getGroupScores(scores, groupMapping);
    let initialKeys = Object.getOwnPropertySymbols(groupScores);
    // MN may be added by score adjustment in one constituency
    if (this.props.adjustScores && !initialKeys.includes(Parties.MN)) {
      initialKeys.push(Parties.MN);
    }
    let keys = initialKeys.sort(
      (a, b) => this.getSortOrder(a) - this.getSortOrder(b)
    );

    let labels = CONSTITUENCIES.map(c => c.name);
    let datasetMap = {};
    for (const key of keys) {
      datasetMap[key] = {
        label: this.getName(key),
        data: [],
        backgroundColor: this.getColor(key),
        borderColor: this.getAccentColor(key),
        borderWidth: {
          top: 1,
          bottom: 1,
          left: 0,
          right: 0,
        },
        borderRadius: 2,
      };
    }
    const totalVotes = totalVotesPerParty(scores, groupMapping, this.props.adjustScores);
    const sortedConstituencies = CONSTITUENCIES.sort((a, b) => a.name.localeCompare(b.name));
    for (const c of sortedConstituencies) {
      const newScores = this.props.adjustScores ? adjustConstituencyScores(c, scores, groupMapping) : groupScores;
      const seats = calculateSeats(newScores, groupScores, c.seats, totalVotes, c.number);
      for (const key of keys) {
        let value = isGovernment(key) ? -seats[key] : seats[key];
        datasetMap[key].data.push(value);
      }
    }
    return {
      labels: labels,
      datasets: keys.map(key => datasetMap[key]),
    };
  }

  render() {
    const seatData = this.buildBarData(this.props.seats, "Mandaty");
    const scores =
      this.props.showUndecidedVoters ? this.props.scores : getNormalisedKnownScores(this.props.scores);
    const scoreData =
      this.buildBarData(
        getGroupScores(scores, this.props.groupMapping),
        "Poparcie",
      );
    const keys = this.getKeys(this.props.seats);
    return (
      <div>
        {this.props.displayScores &&
          <div className="score-chart">
            <BarChart
              data={scoreData}
              axisMin={0}
              axisMax={100}
              showLegend={false}
              marker={50}
              tickValues={[0, 25, 50, 75, 100]}
              showPercentage={true}
            />
          </div>
        }
        <div className="seat-chart">
          <BarChart
            data={seatData}
            axisMin={0}
            axisMax={460}
            showLegend={false}
            hideXLabels={this.props.hideLabels}
            hideYLabels={this.props.hideLabels}
            marker={230}
            tickValues={[0, 153, 184, 230, 276, 307, 460]}
          />
        </div>
        {this.props.displayLegend &&
          <ul className="legend list-inline mb-0">
            {keys.map(key =>
              <li className="list-inline-item" key={key.description}>
                <span className={`${this.getPartyClass(key)} p-icon`}>
                  <FontAwesomeIcon icon={faSquare} />
                </span>
                <span className="p-name text-truncate">{this.getName(key)}</span>
                <span className="p-value">&nbsp;({this.props.seats[key]})</span>
              </li>
            )}
          </ul>
        }
      </div>
    );
  }
}

export class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smallViewport: this.isSmallViewport(),
    };

    window.addEventListener("resize", () => {
      if (this.isSmallViewport() != this.state.smallViewport) {
        this.setState({
          smallViewport: this.isSmallViewport()
        });
      }
    });
  }

  /* 
    Render the chart only if chart data has changed.
    Prevents expensive rendering of the bar chart.
  */
  shouldComponentUpdate(nextProps, _nextState) {
    if (this.props.data.datasets.length != nextProps.data.datasets.length) {
      return true;
    }
    for (let i = 0; i < this.props.data.datasets.length; ++i) {
      if (
        this.props.data.datasets[i].data[0] != nextProps.data.datasets[i].data[0] ||
        this.props.data.datasets[i].label != nextProps.data.datasets[i].label
      ) {
        return true;
      }
    }
    return false;
  }

  isSmallViewport() {
    let width = window.innerWidth;
    return width && width < 576;
  }

  render() {
    const options = {
      scales: {
        x: {
          min: this.props.axisMin,
          max: this.props.axisMax,
          display: !this.props.hideXLabels,
          stacked: true,
          afterBuildTicks: axis => axis.ticks = this.props.tickValues.map(v => ({ value: v })),
          ticks: {
            callback: (value, index, ticks) =>
              this.props.showPercentage ? value + '%' : value,
            min: this.props.axisMin,
            max: this.props.axisMax,
            maxRotation: this.isSmallViewport() ? 90 : 0,
            minRotation: this.isSmallViewport() ? 90 : 0,
            font: {
              size: this.isSmallViewport() ? 9 : 12,
            },
            display: !this.props.hideXTicks,
          },
        },
        y: {
          stacked: true,
          afterFit: this.props.hideYLabels ? null : scaleInstance => {
            scaleInstance.width = this.isSmallViewport() ? 50 : 120;
          },
          display: !this.props.hideYLabels,
          ticks: {
            font: {
              size: this.isSmallViewport() ? 9 : 12,
            },
            maxRotation: this.isSmallViewport() ? 45 : 0,
            minRotation: this.isSmallViewport() ? 45 : 0,
          },
        },
      },
      barPercentage: 1.0,
      categoryPercentage: 1.0,
      indexAxis: 'y',
      maintainAspectRatio: false,
      layout: {
        padding: this.props.hideXLabels ? null : {
          right: 20,
        },
      },
      plugins: {
        datalabels: {
          display: context => {
            let length = this.props.axisMax - this.props.axisMin;
            return Math.abs(context.dataset.data[context.dataIndex]) / length > 0.01 ? 'auto' : false
          },
          color: 'rgba(255,255,255,1)',
          formatter: (value, context) =>
            this.props.showPercentage ? Math.abs(value) + '%' : Math.abs(value),
          font: {
            size: this.isSmallViewport() ? 9 : 12,
          }
        },
        legend: {
          display: this.props.showLegend,
          position: 'bottom',
          labels: {
            boxWidth: this.isSmallViewport() ? 9 : 12,
          }
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.dataset.label + ": " + Math.abs(context.dataset.data[context.dataIndex]);
            }
          }
        },
        annotation: {
          annotations: {
            line1: !this.props.marker ? null : {
              type: 'line',
              xMin: this.props.marker,
              xMax: this.props.marker,
              borderColor: 'rgba(255, 255, 255, 0.3)',
              borderWidth: 2,
              borderDash: [5],
            }
          }
        }
      },
      animation: {
        duration: 50,
      },
    };

    return (
      <Bar data={this.props.data} options={options} />
    );
  }
}

export class ConstituencyResults extends SeatChart {
  getSortOrder(key) {
    return PARTY_DATA.hasOwnProperty(key) ? PARTY_DATA[key].sortOrder : GROUP_DATA[key].sortOrder;
  }

  getColor(key) {
    return PARTY_DATA.hasOwnProperty(key) ? PARTY_DATA[key].color : GROUP_DATA[key].color;
  }

  getAccentColor(key) {
    return PARTY_DATA.hasOwnProperty(key) ? PARTY_DATA[key].accentColor : GROUP_DATA[key].accolorcentColor;
  }

  getPartyClass(key) {
    return 'party-' + key.description;
  }

  getConstituencies() {
    if (!this.props.constituencies) {
      return [...CONSTITUENCIES];
    }
    return this.props.constituencies.map(id => CONSTITUENCY_MAP[id]);
  }

  buildConstituencyData() {
    const groupScores = getGroupScores(this.props.scores, this.props.groupMapping);
    let initialKeys = Object.getOwnPropertySymbols(groupScores);
    // MN may be added by score adjustment in one constituency
    if (this.props.adjustScores && !initialKeys.includes(Parties.MN)) {
      initialKeys.push(Parties.MN);
    }
    const totalVotes = totalVotesPerParty(this.props.scores, this.props.groupMapping, this.props.adjustScores);
    const sortedConstituencies = this.getConstituencies().sort((a, b) => a.name.localeCompare(b.name));
    let data = [];
    for (const c of sortedConstituencies) {
      const newScores = this.props.adjustScores ? adjustConstituencyScores(c, this.props.scores, this.props.groupMapping) : groupScores;
      const seats = calculateSeats(newScores, groupScores, c.seats, totalVotes, c.number);
      data.push({
        constituency: c,
        seatsData: this.buildBarData(seats, "Mandaty"),
        scoresData: this.buildBarData(this.props.showUndecidedVoters ? newScores : getNormalisedKnownScores(newScores), "Poparcie"),
        seats: seats,
        scores: newScores,
      });
    }
    return data;
  }

  buildBarData(source, title = "") {
    let keys = Object.getOwnPropertySymbols(source)
      .sort(
        (a, b) => this.getSortOrder(a) - this.getSortOrder(b)
      );
    let datasets = keys.map(key => {
      return {
        label: this.getName(key),
        data: [roundToDecimal(source[key])],
        backgroundColor: this.getColor(key),
        borderColor: this.getAccentColor(key),
      }
    });
    return {
      labels: [title],
      datasets: datasets,
    };
  }

  render() {
    return (
      <>
        <div className={`row results pt-3 g-0 ${isInternalVersion() ? 'results-export' : ''}`} id={this.props.elementId}>
          <div className="col-xl-4 pt-2">
            <h3>
              <FontAwesomeIcon icon={faMagnifyingGlassChart} className="header-icon" />
              {this.props.title}
            </h3>
          </div>
          <div className="col-xl-8 pt-xl-2">
            {this.props.adjustScores &&
              <p className="description">
                <FontAwesomeIcon icon={faInfoCircle} className="header-icon" />
                Różnice poparcia pomiędzy okręgami wynikają z włączonej korekcji poparcia w okręgach
                na podstawie wyników wyborów w 2023 roku. Możesz ją wyłączyć w opcjach powyżej.
              </p>
            }
          </div>
        </div>
        {
          this.buildConstituencyData().map(item =>
            <div
              key={item.constituency.id}
              className={`row results pt-0 pb-4 g-0 constituency-overview ${isInternalVersion() ? 'results-export' : ''}`}>
              <div className="col-xl-2 pt-2 pe-xl-4">
                <div className="d-flex d-xl-block">
                  <div className="mr-auto text-start text-xl-end constituency-name">
                    <h4>
                      {item.constituency.nameFull || item.constituency.name}{' '}
                    </h4>
                  </div>
                  <div className="d-block d-xl-none ms-2 text-start me-auto">
                    <span className="constituency-number">Nr {item.constituency.number}</span>{' '}
                  </div>
                  <div className="text-end constituency-details">
                    <strong>{item.constituency.seats}</strong> mandatów<br />
                  </div>
                  <div className="d-none d-xl-block text-end">
                    <span className="constituency-number">Nr {item.constituency.number}</span>{' '}
                  </div>
                </div>
              </div>
              <div className="col-xl-2">
                <SmallSeatCards
                  oppositionSeats={item.constituency.seats - totalForGroup(item.seats, isGovernment)}
                  pisSeats={totalForGroup(item.seats, isPis)}
                  konfederacjaSeats={totalForGroup(item.seats, isKonfederacja)} />
              </div>
              <div className="col-xl-8 px-0 px-md-2">
                <div className="constituency-score-chart">
                  <BarChart
                    data={item.scoresData}
                    axisMin={0}
                    axisMax={100}
                    showLegend={false}
                    marker={50}
                    hideXLabels={true}
                    tickValues={[0, 25, 50, 75, 100]}
                    showPercentage={true} />
                </div>
                <div className="constituency-seat-chart">
                  <BarChart
                    key={item.constituency.name}
                    data={item.seatsData}
                    showLegend={false}
                    marker={item.constituency.seats / 2}
                    axisMin={0}
                    axisMax={item.constituency.seats}
                    hideXLabels={true}
                    tickValues={[0, item.constituency.seats]} />
                </div>
              </div>
            </div>
          )
        }
      </>
    );
  }
}