import ReactDOM from "react-dom";
import { App } from "./App";
import '../scss/custom.scss';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import ReactPixel from 'react-facebook-pixel';

const firebaseConfig = {
  apiKey: "AIzaSyDpfCyNhtYvZzZNcRpCvIemhYsVIS9hVtc",
  authDomain: "kalkulator-opozycji.firebaseapp.com",
  projectId: "kalkulator-opozycji",
  storageBucket: "kalkulator-opozycji.appspot.com",
  messagingSenderId: "232036408925",
  appId: "1:232036408925:web:b8d36fdbceb4607d74b7b4",
  measurementId: "G-RVLQ6D0KNN"
};

if (process.env.NODE_ENV === 'production') {
  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  const firebaseAnalytics = getAnalytics(firebaseApp);

  ReactPixel.init('474278537778849');
  ReactPixel.pageView();
}

const app = document.getElementById("app");
ReactDOM.render(<App />, app);