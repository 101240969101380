import React from "react";
import { Helmet } from "react-helmet";
import {
  calculateSeats,
} from '/src/calc.js';
import {
  DEFAULT_PARTY_ORDER,
  GroupingOption,
  Parties,
} from '/src/config.js';
import { noBlocksGroupMapping } from "/src/transform.js";
import { TryCalculatorButton } from "/src/navigation";
import { ShareButtons, DHondtExplanationSharingButtons } from "/src/sharing";
import { Tooltip as RBTooltip, OverlayTrigger } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  annotationPlugin,
);
import { ExampleChart } from '/src/pages/why';
import { CONSTITUENCIES } from "/src/config";

const TOTAL_VOTES = {
  [Parties.PIS]: 33.4,
  [Parties.KO]: 28.5,
  [Parties.LEWICA]: 10.7,
  [Parties.TRZECIA_DROGA]: 11,
  [Parties.KONFEDERACJA]: 14.5,
  [Parties.POZOSTALI]: 1.9,
}

const DHONDT_EXAMPLE_SEATS = 9;
const DHONDT_EXAMPLE_GROUP_MAPPING = {
  [Parties.PIS]: Parties.PIS,
  [Parties.KO]: GroupingOption.GROUP_1,
  [Parties.LEWICA]: GroupingOption.GROUP_1,
  [Parties.TRZECIA_DROGA]: Parties.TRZECIA_DROGA,
  [Parties.KONFEDERACJA]: Parties.KONFEDERACJA,
  [Parties.POZOSTALI]: Parties.POZOSTALI,
}

const DHONDT_EXAMPLE_1_SCORES = {
  [Parties.PIS]: 38,
  [Parties.KO]: 36,
  [Parties.LEWICA]: 8,
  [Parties.TRZECIA_DROGA]: 10,
  [Parties.POZOSTALI]: 8,
};

const DHONDT_EXAMPLE_2_SCORES = {
  [Parties.PIS]: 38,
  [Parties.KO]: 36,
  [Parties.LEWICA]: 11,
  [Parties.TRZECIA_DROGA]: 10,
  [Parties.POZOSTALI]: 5,
};

const DHONDT_EXAMPLE_3_SCORES = {
  [Parties.PIS]: 38,
  [Parties.KO]: 36,
  [Parties.LEWICA]: 11,
  [Parties.TRZECIA_DROGA]: 7,
  [Parties.POZOSTALI]: 8,
};

const DHONDT_EXAMPLE_4_1_SCORES = {
  [Parties.PIS]: 48,
  [Parties.KO]: 33,
  [Parties.LEWICA]: 7,
  [Parties.TRZECIA_DROGA]: 12,
};

const DHONDT_EXAMPLE_4_2_SCORES = {
  [Parties.PIS]: 50.1,
  [Parties.KO]: 29.9,
  [Parties.LEWICA]: 5.1,
  [Parties.TRZECIA_DROGA]: 14.9,
  [Parties.POZOSTALI]: 0,
};

export class DhondtMethodExplanation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPartyOrder: DEFAULT_PARTY_ORDER,
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <Helmet>
          <title>Jak działa metoda D'Hondta? | Kalkulator Sejmowy</title>
          <meta property="og:title" content="Jak działa metoda D'Hondta? | Kalkulator Sejmowy" />
        </Helmet>
        <div className="row chapter chapter-0">
          <div className="col-xl-8">
            <h2>Jak działa metoda D'Hondta?</h2>
            <p>
              Metoda D'Hondta jest stosunkowo prostym mechanizmem podziału mandatów,
              ale efekty jej stosowania potrafią być zaskakujące.
              Pojawiają się też na jej temat różne mity,
              jak np. przechodzenie na zwycięską partię głosów oddanych na partie poniżej progu wyborczego.
            </p>
            <p>
              Metoda D'Hondta rozstrzyga o końcowym wyniku wyborów do Sejmu,
              więc warto ją dokładnie zrozumieć.
            </p>
          </div>
        </div>
        <div className="row chapter chapter-1">
          <div className="col-xl-8">
            <h3>
              <span className="header-number">1.</span>
              O co chodzi w metodzie D'Hondta?
            </h3>
            <strong>Przygotowanie: </strong>
            <p>Załóżmy, że mamy pewną liczbę mandatów do rozdania i znamy liczbę głosów oddanych
              na każdą listę wyborczą. Żeby je rozdać, rozegramy dokładnie tyle rund ile jest mandatów do rozdania.</p>

            <strong>Podział: </strong>
            <p>W każdej rundzie dzielimy liczbę głosów oddanych na każdą z list przez liczbę mandatów, które
              tej liście już przypadły powiększoną&nbsp;o&nbsp;1.</p>

            <p className="mb-0">
              Na przykład:
            </p>
            <ul>
              <li>
                Lista, która otrzymała 100 głosów i jeszcze nie otrzymała żadnego mandatu, do aktualnej rundy przystąpi z wynikiem: 100/(0+1)=100.
              </li>
              <li>
                Lista która otrzymała 100 głosów i już 3 mandaty, do aktualnej rundy przystąpi z wynikiem: 100/(3+1)=25.
              </li>
            </ul>
            <p>
              Lista, której przypadł najwyższy wynik w danej rundzie, otrzymuje kolejny mandat.
              Rozgrywamy dalsze rundy aż do momentu, w którym skończą nam się mandaty.
            </p>

            <aside>
              <p>
                Jak rozstrzygamy remisy? Jeśli więcej niż jedna lista uzyskała w danej rundzie ten sam najwyższy wynik
                i pozostałych do rozdania mandatów wystarczy dla każdej z nich, każda dostaje po jednym mandacie.
              </p>
              <p className="mt-2">
                Jeśli mandatów nam nie wystarczy, stosujemy Art.&nbsp;232.&nbsp;§&nbsp;2 Kodeksu Wyborczego i wtedy:
                <ul>
                  <li>Mandat dostaje lista, na którą w sumie oddano więcej głosów w całej Polsce; albo</li>
                  <li>Jeśli liczba głosów jest taka sama, mandat otrzymuje lista, na którą oddano więcej głosów
                    w większej liczbie okręgów.</li>
                </ul>
              </p>
            </aside>

            <strong>Wybór kandydatów: </strong>
            <p>
              Po obliczeniu liczby mandatów przypadających każdej z list wyborczych, wybieramy z każdej z nich tych kandydatów,
              którzy otrzymali najwięcej głosów.
            </p>
            <aside>
              <p>Warto też przypomnieć, że wybory do Sejmu odbywają się w 41 okręgach
                i podział mandatów dokonywany jest osobno w każdym z nich.
                Każdemu okręgowi przysługuje od 7 do 20 mandatów w zależności od liczby mieszkańców.
                Nie rozdajemy więc wszystkich 460 mandatów naraz.
              </p>
            </aside>
          </div>
          <div className="col-xl-4 d-xl-block d-none">
            <DHondtExplanationSharingButtons />
          </div>
        </div>
        <div className="row chapter chapter-2">
          <div className="col-xl-8 dhondt-example">
            <h3>
              <span className="header-number">2.</span>
              Przykład
            </h3>
            <p>Wyobrażmy sobie, że zorganizowaliśmy wybory w klubie sportowym w celu wybrania 4-osobowego zarządu.
              Przystąpiły do nich trzy partie:{' '}
              <mark className="party-a">Rowerzystów</mark>,{' '}
              <mark className="party-b">Biegaczy</mark>,
              i <mark className="party-c">Pływaków</mark>.{' '}
              Głosowało 100 osób i otrzymaliśmy następujące wyniki:
              <ul>
                <li><mark className="party-a">50 głosów na Rowerzystów</mark>,</li>
                <li><mark className="party-b">30 głosów na Biegaczy</mark>,</li>
                <li><mark className="party-c">20 głosów na Pływaków</mark>.</li>
              </ul>
            </p>
            <p>
              Do rozdania są 4 mandaty, rozegramy więc 4 rundy.
            </p>
            <p>
              <ol>
                <li>W pierwszej rundzie żadna z list nie otrzymała jeszcze mandatów, więc{' '}
                  Rowerzyści mają wynik 50/(0+1)=50,{' '}
                  Biegacze 30/(0+1)=30,
                  a Pływacy 20/(0+1)=20. <strong><mark className="party-a">Rowerzyści</mark> wygrywają</strong> i otrzymują 1 mandat.
                </li>
                <li>W drugiej rundzie, Rowerzyści mają wynik 50/(1+1)=25, ponieważ otrzymali już jeden mandat.
                  Biegacze nadal mają wynik 30/(0+1)=30,
                  a Pływacy 20/(0+1)=20. <strong>Wygrywają więc <mark className="party-b">Biegacze</mark></strong> i otrzymują 1 mandat.
                </li>
                <li>
                  W trzeciej rundzie, Rowerzyści ponownie mają wynik 50/(1+1)=25, Biegacze otrzymują 30/(1+1)=15,
                  a Pływacy zostają przy 20/(0+1)=20.
                  Ponownie <strong>wygrywają więc <mark className="party-a">Rowerzyści</mark></strong> i otrzymują drugi mandat.
                </li>
                <li>
                  W czwartej rundzie, Rowerzyści mają wynik 50/(2+1)=16.67, Biegacze 30/(1+1)=15, a Pływacy 20/(0+1)=20.
                  Tym razem <strong>wygrywają <mark className="party-c">Pływacy</mark></strong> i otrzymują swój pierwszy i jedyny mandat.
                </li>
              </ol>
            </p>
            <p>
              Po czterech rundach zakończyliśmy więc wybory z następującym wynikiem:
              <mark className="party-a">2 mandaty dla Rowerzystów</mark>,{' '}
              <mark className="party-b">1 dla Biegaczy</mark>{' '}
              <mark className="party-c">i 1 dla Pływaków</mark>.
            </p>
          </div>
        </div>
        <div className="row chapter chapter-3">
          <div className="col-xl-8">
            <h3>
              <span className="header-number">3.</span>
              Metoda w praktyce
            </h3>
            <p>Przyjrzyjmy się kilku bardziej realistycznym (ale uproszczonym) przykładom w niewielkim okręgu wyborczym
              z 9 mandatami do rozdania. Takich rzeczywistych okręgów wyborczych jest kilka. Są to
              m.in. Bielsko-Biała I, Katowice I, Konin, Piła, Piotrków Trybunalski, oraz Tarnów.
            </p>
          </div>
        </div>
        <div className="row chapter chapter-3">
          <div className="col-xl-8">
            <h4>
              <span className="header-number">3.1.</span>
              Pierwszy przykład
            </h4>
            <p>Załóżmy, że próg przekroczyły tylko 4 listy: PiS, KO, Trzecia Droga, i Lewica.
              W naszym okręgu PiS otrzymał 38% głosów, KO 36%, Trzecia Droga 10%, Lewica 8%.
              Pozostałe 8% głosów zmarnowało się. Dla uproszczenia założmy też, że w okręgu głosowało 100 000 osób,
              więc PiS dostał 38&nbsp;000, KO 36&nbsp;000, Trzecia Droga 10&nbsp;000, Lewica 8000.</p>
            <p>Kiedy rozegramy wszystkie rundy podziału mandatów, otrzymamy kolejno:</p>
            <figure>
              <ol>
                <li>
                  <strong>PiS <Res v={38000} seats={0} /></strong>,{' '}
                  KO <Res v={36000} seats={0} />,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={8000} seats={0} />{' '}
                  &mdash; pierwszy mandat dla PiS.
                </li>
                <li>
                  PiS <Res v={38000} seats={1} />,{' '}
                  <strong>KO <Res v={36000} seats={0} /></strong>,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={8000} seats={0} />{' '}
                  &mdash; pierwszy mandat dla KO.
                </li>
                <li>
                  <strong>PiS <Res v={38000} seats={1} /></strong>,{' '}
                  KO <Res v={36000} seats={1} />,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={8000} seats={0} />{' '}
                  &mdash; drugi mandat dla PiS.
                </li>
                <li>
                  PiS <Res v={38000} seats={2} />,{' '}
                  <strong>KO <Res v={36000} seats={1} /></strong>,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={8000} seats={0} />{' '}
                  &mdash; drugi mandat dla KO.
                </li>
                <li>
                  <strong>PiS <Res v={38000} seats={2} /></strong>,{' '}
                  KO <Res v={36000} seats={2} />,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={8000} seats={0} />{' '}
                  &mdash; trzeci mandat dla PiS.
                </li>
                <li>
                  PiS <Res v={38000} seats={3} />,{' '}
                  <strong>KO <Res v={36000} seats={2} /></strong>,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={8000} seats={0} />{' '}
                  &mdash; trzeci mandat dla KO.
                </li>
                <li>
                  PiS <Res v={38000} seats={3} />,{' '}
                  KO <Res v={36000} seats={3} />,{' '}
                  <strong>Trzecia Droga <Res v={10000} seats={0} /></strong>,{' '}
                  Lewica <Res v={8000} seats={0} />{' '}
                  &mdash; mandat dla Trzeciej Drogi.
                </li>
                <li>
                  <strong>PiS <Res v={38000} seats={3} /></strong>,{' '}
                  KO <Res v={36000} seats={3} />,{' '}
                  Trzecia Droga <Res v={10000} seats={1} />,{' '}
                  Lewica <Res v={8000} seats={0} />{' '}
                  &mdash; czwarty mandat dla PiS.
                </li>
                <li>
                  PiS <Res v={38000} seats={4} />,{' '}
                  <strong>KO <Res v={36000} seats={3} /></strong>,{' '}
                  Trzecia Droga <Res v={10000} seats={1} />,{' '}
                  Lewica <Res v={8000} seats={0} />{' '}
                  &mdash; czwarty mandat dla KO.
                </li>
              </ol>
            </figure>
            <p>
              Rozdaliśmy wszystkie 9 mandatów i niestety zabrakło ich dla Lewicy, mimo dość dobrego wyniku.
              Ostateczny podział jest więc następujący:
            </p>
            <div className="row justify-content-center">
              <div className="col-xl-11 example-chart example-smaller" >
                <ExampleChart
                  totalSeats={DHONDT_EXAMPLE_SEATS}
                  seatTickValues={[0, 4, 5, 9]}
                  seats={calculateSeats(
                    DHONDT_EXAMPLE_1_SCORES,
                    DHONDT_EXAMPLE_1_SCORES,
                    DHONDT_EXAMPLE_SEATS,
                    TOTAL_VOTES,
                  )}
                  scores={DHONDT_EXAMPLE_1_SCORES}
                  groupMapping={noBlocksGroupMapping(DEFAULT_PARTY_ORDER)}
                  adjustScores={false}
                  displayScores={true}
                  displayLegend={true}
                  showUndecidedVoters={true} />
              </div>
            </div>
          </div>
        </div>
        <div className="row chapter chapter-4">
          <div className="col-xl-8">
            <h4>
              <span className="header-number">3.2.</span>
              Drugi przykład: lepszy wynik Lewicy
            </h4>
            <p>Załóżmy teraz, że Lewicy jednak poszło znacznie lepiej i odebrała jeszcze 3 punkty procentowe poparcia innym partiom.
              Dostała więc 11%, a partie poniżej progu w sumie tylko 5%.
            </p>
            <p>Początkowe rundy przebiegają podobnie. Zmiana następuje dopiero w siódmej rundzie:</p>
            <figure>
              <ol>
                <li>
                  <strong>PiS <Res v={38000} seats={0} /></strong>,{' '}
                  KO <Res v={36000} seats={0} />,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; pierwszy mandat dla PiS.
                </li>
                <li>
                  PiS <Res v={38000} seats={1} />,{' '}
                  <strong>KO <Res v={36000} seats={0} /></strong>,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; pierwszy mandat dla KO.
                </li>
                <li>
                  <strong>PiS <Res v={38000} seats={1} /></strong>,{' '}
                  KO <Res v={36000} seats={1} />,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; drugi mandat dla PiS.
                </li>
                <li>
                  PiS <Res v={38000} seats={2} />,{' '}
                  <strong>KO <Res v={36000} seats={1} /></strong>,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; drugi mandat dla KO.
                </li>
                <li>
                  <strong>PiS <Res v={38000} seats={2} /></strong>,{' '}
                  KO <Res v={36000} seats={2} />,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; trzeci mandat dla PiS.
                </li>
                <li>
                  PiS <Res v={38000} seats={3} />,{' '}
                  <strong>KO <Res v={36000} seats={2} /></strong>,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; trzeci mandat dla KO.
                </li>
                <li>
                  PiS <Res v={38000} seats={3} />,{' '}
                  KO <Res v={36000} seats={3} />,{' '}
                  Trzecia Droga <Res v={10000} seats={0} />,{' '}
                  <strong>Lewica <Res v={11000} seats={0} /></strong>{' '}
                  &mdash; mandat dla Lewicy.
                </li>
                <li>
                  PiS <Res v={38000} seats={3} />,{' '}
                  KO <Res v={36000} seats={3} />,{' '}
                  <strong>Trzecia Droga <Res v={10000} seats={0} /></strong>,{' '}
                  Lewica <Res v={11000} seats={1} />{' '}
                  &mdash; mandat dla Trzeciej Drogi.
                </li>
                <li>
                  <strong>PiS <Res v={38000} seats={3} /></strong>,{' '}
                  KO <Res v={36000} seats={3} />,{' '}
                  Trzecia Droga <Res v={10000} seats={1} />,{' '}
                  Lewica <Res v={11000} seats={1} />{' '}
                  &mdash; czwarty mandat dla PiS.
                </li>
              </ol>
            </figure>
            <p>
              Rozdaliśmy wszystkie 9 mandatów.
              Tym razem Lewica załapała się na mandat, ale kosztem mandatu dla KO.
              To daje nam następujący rezultat:
            </p>

            <div className="row justify-content-center">
              <div className="col-xl-11 example-chart example-smaller" >
                <ExampleChart
                  totalSeats={DHONDT_EXAMPLE_SEATS}
                  seatTickValues={[0, 4, 5, 9]}
                  seats={calculateSeats(
                    DHONDT_EXAMPLE_2_SCORES,
                    DHONDT_EXAMPLE_2_SCORES,
                    DHONDT_EXAMPLE_SEATS,
                    TOTAL_VOTES,
                  )}
                  scores={DHONDT_EXAMPLE_2_SCORES}
                  groupMapping={noBlocksGroupMapping(DEFAULT_PARTY_ORDER)}
                  adjustScores={false}
                  displayScores={true}
                  displayLegend={true}
                  showUndecidedVoters={true} />
              </div>
            </div>
          </div>
        </div>
        <div className="row chapter chapter-5">
          <div className="col-xl-8">
            <h4>
              <span className="header-number">3.3.</span>
              Trzeci przykład: "zmarnowane głosy przechodzą na zwycięzcę"
            </h4>
            <p>
              Często powtarzanym mitem jest, że głosy oddane na listy, które nie przekroczyły progu, przechodzą na zwycięzcę.
              Załóżmy zatem, że Trzecia Droga spadła pod próg wyborczy zdobywając tylko 7% głosów.
            </p>
            <p>
              Rozegramy teraz kolejne rundy, już bez Trzeciej Drogi:
            </p>
            <figure>
              <ol>
                <li>
                  <strong>PiS <Res v={38000} seats={0} /></strong>,{' '}
                  KO <Res v={36000} seats={0} />,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; pierwszy mandat dla PiS.
                </li>
                <li>
                  PiS <Res v={38000} seats={1} />,{' '}
                  <strong>KO <Res v={36000} seats={0} /></strong>,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; pierwszy mandat dla KO.
                </li>
                <li>
                  <strong>PiS <Res v={38000} seats={1} /></strong>,{' '}
                  KO <Res v={36000} seats={1} />,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; drugi mandat dla PiS.
                </li>
                <li>
                  PiS <Res v={38000} seats={2} />,{' '}
                  <strong>KO <Res v={36000} seats={1} /></strong>,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; drugi mandat dla KO.
                </li>
                <li>
                  <strong>PiS <Res v={38000} seats={2} /></strong>,{' '}
                  KO <Res v={36000} seats={2} />,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; trzeci mandat dla PiS.
                </li>
                <li>
                  PiS <Res v={38000} seats={3} />,{' '}
                  <strong>KO <Res v={36000} seats={2} /></strong>,{' '}
                  Lewica <Res v={11000} seats={0} />{' '}
                  &mdash; trzeci mandat dla KO.
                </li>
                <li>
                  PiS <Res v={38000} seats={3} />,{' '}
                  KO <Res v={36000} seats={3} />,{' '}
                  <strong>Lewica <Res v={11000} seats={0} /></strong>.{' '}
                  &mdash; mandat dla Lewicy.
                </li>
                <li>
                  <strong>PiS <Res v={38000} seats={3} /></strong>,{' '}
                  KO <Res v={36000} seats={3} />,{' '}
                  Lewica <Res v={11000} seats={1} />{' '}
                  &mdash; czwarty mandat dla PiS.
                </li>
                <li>
                  PiS <Res v={38000} seats={4} />,{' '}
                  <strong>KO <Res v={36000} seats={3} /></strong>,{' '}
                  Lewica <Res v={11000} seats={1} />{' '}
                  &mdash; czwarty mandat dla KO.
                </li>
              </ol>
            </figure>
            <p>W efekcie otrzymujemy:</p>
            <div className="row justify-content-center">
              <div className="col-xl-11 example-chart example-smaller" >
                <ExampleChart
                  totalSeats={DHONDT_EXAMPLE_SEATS}
                  seatTickValues={[0, 4, 5, 9]}
                  seats={calculateSeats(
                    DHONDT_EXAMPLE_3_SCORES,
                    DHONDT_EXAMPLE_3_SCORES,
                    DHONDT_EXAMPLE_SEATS,
                    TOTAL_VOTES,
                  )}
                  scores={DHONDT_EXAMPLE_3_SCORES}
                  groupMapping={noBlocksGroupMapping(DEFAULT_PARTY_ORDER)}
                  adjustScores={false}
                  displayScores={true}
                  displayLegend={true}
                  showUndecidedVoters={true} />
              </div>
            </div>
            <p>
              Jak widzimy, spadnięcie Trzeciej Drogi pod próg pozwoliło KO odzyskać jeden mandat, ale gdyby faktycznie istniała
              premia dla zwycięzcy, mandat ten trafiłby do PiS.
            </p>
            <p>
              Oczywiście możliwych jest wiele scenariuszy w zależności
              od konkretnych wyników w okręgu i liczby mandatów do rozdania.
              Lista o największym poparciu ma największe szanse zdobyć kolejny mandat
              (dzięki wyższym wynikom w kolejnych rundach),
              ale głosy nie przechodzą na zwycięzcę i nie istnieje gwarantowana premia.
            </p>
          </div>
        </div>
        <div className="row chapter chapter-6">
          <div className="col-xl-8 pt-4">
            <h4>
              <span className="header-number">3.4.</span>
              Czwarty przykład: "premia D'Hondta" za wysokie poparcie
            </h4>
            <p>
              Metoda D'Hondta nie jest niestety wolna od wad.
              Premiuje ona partie o wyższym poparciu kosztem partii o niższym poparciu i efekt ten
              jest szczególnie widoczny w przypadku okręgów o niewielkiej liczbie mandatów.
            </p>
            <p>
              Premia ta wynika z faktu, że partie o niskim poparciu muszą uzyskać
              wystarczająco wysoki wynik, żeby wygrać którąś z rund.

              Jeżeli tak jak w poprzednim przykładzie PiS uzyska 38 000 głosów,
              a KO 36 000 głosów, do kolejnych rund będą one podchodziły odpowiednio z wynikami:
            </p>
            <ul>
              <li>PiS: {[0, 1, 2, 3, 4, 5].map((i) => <span><Res v={38000} seats={i} />, </span>)}...</li>
              <li>KO: {[0, 1, 2, 3, 4, 5].map((i) => <span><Res v={36000} seats={i} />, </span>)}...</li>
            </ul>
            <p>
              Zatem, żeby zdobyć chociaż jeden mandat, mała partia musi uzyskać więcej niż:
            </p>
            <ul>
              <li>9500 głosów (9.5% poparcia) w okręgu 7-mandatowym,</li>
              <li>9000 głosów (9% poparcia) w okręgu 8-mandatowym,</li>
              <li>7600 głosów (7.6% poparcia) w okręgu 9-mandatowym,</li>
              <li>...i tak dalej.</li>
            </ul>
            <h5>Jak wygląda premia w indywidualnych okręgach?</h5>
            <p>Rozważmy zatem sytuację w której we wszystkich 41 okręgach wszystkie rozważane
              przez nas partie uzyskały taki sam wynik: PiS&nbsp;48%, KO&nbsp;33%, Trzecia&nbsp;Droga&nbsp;12%, Lewica&nbsp;7%.
              W każdym okręgu oddano też dokładnie 100 000 głosów. Prezentuje się to następująco:
            </p>
            <div className="row justify-content-center">
              <div className="col-xl-11 example-chart example-smaller" >
                <ExampleChart
                  totalSeats={20}
                  seatTickValues={[0, 20]}
                  seats={calculateSeats(
                    DHONDT_EXAMPLE_4_1_SCORES,
                    DHONDT_EXAMPLE_4_1_SCORES,
                    20,
                    TOTAL_VOTES,
                  )}
                  scores={DHONDT_EXAMPLE_4_1_SCORES}
                  groupMapping={noBlocksGroupMapping(DEFAULT_PARTY_ORDER)}
                  adjustScores={false}
                  hideSeats={true}
                  showUndecidedVoters={true} />
              </div>
              <p>Przyjrzyjmy się teraz podziałowi mandatów dla każdego istniejącego
                rozmiaru okręgu wyborczego.
              </p>
              {[...new Set(CONSTITUENCIES.map((c) => c.seats))].sort((a, b) => a - b).map((seats) =>
                // Display number of constituencies in CONSTITUENCIES with this number of seats
                <div className="row g-2 justify-content-center">
                  <h6 className="m-0 ms-2 mb-1">{seats} mandatów</h6>
                  <div className="m-0 ms-2 dhondt-example-constituency-list">
                    {CONSTITUENCIES
                      .filter((c) => c.seats === seats)
                      .map((c) => c.nameFull || c.name)
                      .sort((a, b) => a.localeCompare(b))
                      .join(', ')}
                  </div>
                  <div className="col-xl-11 pb-0 example-chart example-tiny" >
                    <ExampleChart
                      totalSeats={seats}
                      seatTickValues={[0, Math.floor(seats / 2), Math.ceil(seats / 2), seats]}
                      seats={calculateSeats(
                        DHONDT_EXAMPLE_4_1_SCORES,
                        DHONDT_EXAMPLE_4_1_SCORES,
                        seats,
                        TOTAL_VOTES,
                      )}
                      scores={DHONDT_EXAMPLE_4_1_SCORES}
                      groupMapping={noBlocksGroupMapping(DEFAULT_PARTY_ORDER)}
                      adjustScores={false}
                      hideScores={true}
                      hideLegend={true}
                      showUndecidedVoters={true} />
                  </div>
                </div>
              )}
            </div>
            <p className="mt-4">Możemy zaobserwować kilka ciekawych zjawisk:</p>
            <ul>
              <li>Widzimy że PiS zdobywa co najmniej 50% mandatów w każdym okręgu, mimo 48% poparcia.</li>
              <li>W niektórych okręgach PiS zdobywa nawet więcej niż połowę mandatów (np. 4 z 7 w Częstochowie lub 6 z 11 w Krośnie).</li>
              <li>KO też często dostaje premię zdobywając np. 40% mandatów w Łodzi mimo 33% poparcia.</li>
              <li>Lewica z poparciem 7% (wynik <Res v={7000} seats={0} />) załapuje się na mandat dopiero w okręgach o co najmniej 12 mandatach.</li>
              <li>Trzecia Droga z poparciem 12% załapuje się na drugi mandat (z wynikiem <Res v={12000} seats={1} />)
                dopiero w okręgach o co najmniej 16 mandatach.</li>
              <li>Trzecia Droga dostaje małą premię w 7-mandatowej Częstochowie (14.3% mandatów przy 12% poparciu), ale
                traci w większych okręgach i np. w 15-mandatowym Lublinie uzyskuje tylko 6.6% mandatów.
              </li>
            </ul>
            <p>Należy jednak podkreślić, że tak jak w poprzednich przykładach, bardzo dużo zależy od konkretnych wyników.
              Jeden głos może zadecydować o uzyskaniu lub nieuzyskaniu kolejnego mandatu.</p>
          </div>
          <div className="col-xl-4 d-xl-block d-none">
            <DHondtExplanationSharingButtons />
          </div>
        </div>
        <div className="row chapter chapter-7">
          <div className="col-xl-8 pt-4">
            <h3>
              <span className="header-number">4.</span>
              Co dalej?
            </h3>
            <p>
              Już na sam koniec warto dodać, że jest kilka równoważnych sposobów przedstawienia metody D'Hondta
              i dokładny opis można znaleźć m.in. <a href="https://pl.wikipedia.org/wiki/Metoda_D%E2%80%99Hondta">w&nbsp;Wikipedii</a>.
              Kodeks wyborczy również definiuje ją inaczej, ale końcowy efekt jest ten sam.
            </p>
            <p>
              Jeśli chcesz się przekonać jak rozkładają się mandaty we wszystkich okręgach, lub
              przetestować różne konfiguracje list wyborczych:
            </p>
          </div>
          <div className="col-xl-8 pb-4 d-flex flex-column align-items-center">
            <TryCalculatorButton />
          </div>
          <div className="col-xl-8 d-xl-none explanation-sharing pt-4 mb-0 d-flex flex-column align-items-center">
            <p>
              Pomógł Ci ten artykuł? Udostępnij go dalej:
            </p>
            <ShareButtons url={document.location} />
          </div>
        </div>
      </div >
    );
  }
}

function Res(props) {
  const result = (props.v / (props.seats + 1));

  return <span>
    <OverlayTrigger
      placement="bottom"
      overlay={
        <RBTooltip>
          <p>
            {props.v}/({props.seats}+1)={result}
          </p>
        </RBTooltip>
      }
    >
      {/* <Button variant="link" className="p-0 tooltip-icon"><FontAwesomeIcon icon={faCircleInfo} /></Button> */}
      <u>{result.toFixed(0)}</u>
    </OverlayTrigger>
  </span>;
}