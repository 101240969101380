import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  RedditShareButton,
} from "react-share";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faFacebookMessenger,
  faWhatsapp,
  faLinkedin,
  faReddit,
} from '@fortawesome/free-brands-svg-icons';

export class ShareButtons extends React.Component {
  render() {
    return (
      <span className="share-buttons">
        <FacebookShareButton url={this.props.url}>
          <FontAwesomeIcon icon={faFacebook} />
        </FacebookShareButton>
        <TwitterShareButton url={this.props.url}>
          <FontAwesomeIcon icon={faTwitter} />
        </TwitterShareButton>
        <LinkedinShareButton url={this.props.url}>
          <FontAwesomeIcon icon={faLinkedin} />
        </LinkedinShareButton>
        <FacebookMessengerShareButton url={this.props.url}>
          <FontAwesomeIcon icon={faFacebookMessenger} />
        </FacebookMessengerShareButton>
        <WhatsappShareButton url={this.props.url}>
          <FontAwesomeIcon icon={faWhatsapp} />
        </WhatsappShareButton>
        <RedditShareButton url={this.props.url}>
          <FontAwesomeIcon icon={faReddit} />
        </RedditShareButton>
      </span>
    );
  }
}

export function ExplanationSharingButtons(props) {
  return (
    <div className="sticky-xl-top text-center p-4 ms-4 explanation-sharing-sticky">
      <p>Uważasz, że wspólna lista to dobry pomysł?</p>
      <p><strong>Pomóż przekonać innych</strong> i udostępnij:</p>
      <ShareButtons url={document.location} />
    </div>
  );
}

export function DHondtExplanationSharingButtons(props) {
  return (
    <div className="sticky-xl-top text-center p-4 ms-4 explanation-sharing-sticky">
      <p>
        Pomógł Ci ten artykuł?
        Udostępnij go dalej:
      </p>
      <ShareButtons url={document.location} />
    </div>
  );
}
