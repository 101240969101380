// Original colours
// $bg-pis: #a2191f;
// $bg-konfederacja: #fa4d56;
// $bg-ko: #9ef0f0;
// $bg-pl2050: #3ddbd9;
// $bg-trzecia_droga: $bg-pl2050;
// $bg-lewica: #08bdba;
// $bg-psl: #009d9a;
// $bg-pozostali: #cecece;
// $bg-mn: #005d5d;
// $bg-group1: #a56eff;
// $bg-group2: #6929c4;
// $bg-niezdecydowani: #979797;

// Party-specific colours  
$bg-pis: #1663A6;
$bg-konfederacja: #27354E;
$bg-ko: #FF761B;
$bg-trzecia_droga: #97C41D;
$bg-lewica: #DE203C;
$bg-pl2050: rgb(250, 212, 0);
$bg-psl: #2ed397;
$bg-pozostali: #c2c2c2;
$bg-mn: #005d5d;
$bg-group2: #D0AD4E;
$bg-group1: #D8BC6E;
$bg-niezdecydowani: #979797;
$bg-opozycja: #e400e4;

$bg-pis: #1663A6;
$bg-konfederacja: #24354D;
$bg-ko: #FF761B;
$bg-trzecia_droga: #97C41D;
$bg-lewica: #DE203C;

$border-pis: lighten($bg-pis, 5);
$border-konfederacja: lighten($bg-konfederacja, 5);
$border-ko: lighten($bg-ko, 5);
$border-pl2050: lighten($bg-pl2050, 5);
$border-trzeia_droga: lighten($bg-trzecia_droga, 5);
$border-lewica: lighten($bg-lewica, 5);
$border-psl: lighten($bg-psl, 5);
$border-pozostali: lighten($bg-pozostali, 5);
$border-mn: lighten($bg-pozostali, 5);
$border-group1: lighten($bg-group1, 5);
$border-group2: lighten($bg-group2, 5);
$border-niezdecydowani: lighten($bg-niezdecydowani, 5);

:export {
    bgPis: $bg-pis;
    bgKonfederacja: $bg-konfederacja;
    bgKo: $bg-ko;
    bgPl2050: $bg-pl2050;
    bgTrzeciaDroga: $bg-trzecia_droga;
    bgLewica: $bg-lewica;
    bgPsl: $bg-psl;
    bgMn: $bg-mn;
    bgPozostali: $bg-pozostali;
    bgGroup1: $bg-group1;
    bgGroup2: $bg-group2;
    bgNiezdecydowani: $bg-niezdecydowani;

    borderPis: $border-pis;
    borderKo: $border-ko;
    borderKonfederacja: $border-konfederacja;
    borderPl2050: $border-pl2050;
    borderTrzeciaDroga: $border-trzeia_droga;
    borderLewica: $border-lewica;
    borderPsl: $border-psl;
    borderMn: $border-mn;
    borderPozostali: $border-pozostali;
    borderGroup1: $border-group1;
    borderGroup2: $border-group2;
    borderNiezdecydowani: $border-niezdecydowani;
}