import React from 'react';
import { toPng } from 'html-to-image';
import * as download from 'downloadjs';
import { Result } from './results.js';
import { noBlocksGroupMapping, singleBlockGroupMapping } from "./transform.js";

export class Downloads extends React.Component {
  constructor(props) {
    super(props);
    this.downloadImage = this.downloadImage.bind(this);
  }

  downloadImage(elementId) {
    return () => {
      toPng(document.getElementById(elementId))
        .then((dataUrl) => {
          download(dataUrl, `${elementId}.png`);
        });
    }
  }

  render() {
    return (
      <div>
        <button
          className="btn btn-secondary"
          onClick={this.downloadImage('results-your-configuration')}>
          Twoja konfiguracja
        </button>
        <button
          className="btn btn-secondary"
          onClick={this.downloadImage('results-single-group')}>
          Wspólna lista
        </button>
        <button
          className="btn btn-secondary"
          onClick={this.downloadImage('results-all-separate')}>
          Wszyscy osobno
        </button>
        <button
          className="btn btn-secondary"
          onClick={this.downloadImage('post-graphic')}>
          Grafika
        </button>
      </div>
    );
  }
}


export class PostGraphic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Tytuł",
      subtitle: "Podtytuł",
      source: "Źródło: "
    };
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSubtitleChange = this.handleSubtitleChange.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
  }

  handleTitleChange(e) {
    this.setState({
      title: e.target.value
    });
  }

  handleSubtitleChange(e) {
    this.setState({
      subtitle: e.target.value
    });
  }

  handleSourceChange(e) {
    this.setState({
      source: e.target.value
    });
  }

  render() {
    return (
      <div>
        <div className="post-graphic-input">
          <form>
            <input
              id="pg-title"
              className="form-control"
              type="text"
              value={this.state.title}
              onChange={this.handleTitleChange} />
            <input
              id="pg-subtitle"
              className="form-control"
              type="text"
              value={this.state.subtitle}
              onChange={this.handleSubtitleChange} />
            <input
              id="pg-source"
              className="form-control"
              type="text"
              value={this.state.source}
              onChange={this.handleSourceChange} />
          </form>
        </div>
        <div className="post-graphic" id="post-graphic">
          <span className="pg-header pg-text">kalkulatorsejmowy.pl</span>
          <span className="pg-title pg-text">{this.state.title}</span>
          <span className="pg-subtitle pg-text">{this.state.subtitle}</span>
          <span className="pg-results-header pg-text">Prognozowane wyniki</span>
          <span className="pg-data-source pt-text">{this.state.source}</span>
          <div className="pg-all-separate">
            <Result
              title="Każda partia osobno"
              scores={this.props.scores}
              groupMapping={noBlocksGroupMapping(this.props.currentPartyOrder)}
              adjustScores={this.props.adjustScores}
              displayConstituencies={false}
              showUndecidedVoters={true} />
          </div>
          <div className="pg-single-group">
            <Result
              title={`Wspólna lista całej ${getCoalitionAccusative(true)}`}
              scores={this.props.scores}
              groupMapping={singleBlockGroupMapping()}
              adjustScores={this.props.adjustScores}
              displayConstituencies={false}
              showUndecidedVoters={true} />
          </div>
        </div>
      </div>
    )
  }
}