import {
  CONSTITUENCIES,
  GroupingOption,
  Parties,
  PREVIOUS_RESULTS,
  getThreshold,
  PAST_SUPPORT,
} from './config.js';
import { totalVotesPerParty } from './wasted_votes.js';

// Adjusts scores to fit to 100% by reducing only scores after `keyToUpdate` in `keyOrder`
export function updateScores(keyOrder, scores, keyToUpdate, newScore, roundValues = true) {
  let startIdx = 0;
  let sumBefore = 0;
  while (keyOrder[startIdx] != keyToUpdate) {
    sumBefore += scores[keyOrder[startIdx]];
    startIdx += 1;
  }

  let oldScore = scores[keyToUpdate];

  let totalSum = keyOrder.reduce((value, key) => value + scores[key], 0);
  let adjustedScore = Math.min(newScore, 100 - sumBefore);
  if (roundValues) {
    adjustedScore = roundToDecimal(adjustedScore);
  }
  let sumAfter = totalSum - oldScore - sumBefore;
  let adjustedSumAfter = totalSum - adjustedScore - sumBefore;

  let ratio = (sumAfter > 0) ? adjustedSumAfter / sumAfter : 1;

  let results = {};
  for (let i = 0; i <= startIdx && i < keyOrder.length; ++i) {
    let currentKey = keyOrder[i];
    if (i < startIdx) {
      results[currentKey] = scores[currentKey];
    } else if (i == startIdx) {
      results[currentKey] = adjustedScore;
    }
  }

  let modifiedResults = {};
  for (let i = startIdx + 1; i < keyOrder.length; ++i) {
    let currentKey = keyOrder[i];
    if (sumAfter > 0) {
      modifiedResults[currentKey] = scores[currentKey] * ratio;
    } else {
      modifiedResults[currentKey] = adjustedSumAfter / (keyOrder.length - startIdx - 1);
    }
  }

  const finalResults = Object.assign(
    {},
    results,
    normaliseTo(modifiedResults, 100 - adjustedScore - sumBefore)
  );

  return finalResults;
}

export function updateScoresOneEntryOnly(keyOrder, scores, keyToUpdate, newScore, roundValues = true) {
  // select the last non-zero key every time, unless it's the key being adjusted
  let keyToAdjust = keyOrder[keyOrder.length - 1];
  let sum = scores[keyToUpdate];
  let adjustedScores = {};
  if (newScore > sum) {
    for (let i = keyOrder.length - 1; i >= 0; --i) {
      if (newScore <= sum) {
        break;
      }
      let currentKey = keyOrder[i];
      if (currentKey != keyToUpdate) {
        keyToAdjust = currentKey;
        sum += scores[keyToAdjust];
        adjustedScores[keyToAdjust] = sum - Math.min(newScore, sum);
      }
    }
  } else {
    if (keyToAdjust === keyToUpdate) {
      keyToAdjust = keyOrder[keyOrder.length - 2];
    }
    sum += scores[keyToAdjust];
    adjustedScores[keyToAdjust] = sum - newScore;
  }

  let updatedScore = Math.min(newScore, sum);
  adjustedScores[keyToUpdate] = updatedScore;

  const finalScores = Object.assign(
    {},
    scores,
    normaliseTo(adjustedScores, sum, roundValues),
  );
  return finalScores;
}

export function roundToDecimal(value) {
  return Math.round(value * 100) / 100;
}

export function floorToDecimal(value) {
  return Math.floor(value * 10) / 10;
}

export function normalise(scores, roundValues = true) {
  return normaliseTo(scores, 100, roundValues);
}

export function normaliseTo(scores, target, roundValues = true) {
  const keys = Object.getOwnPropertySymbols(scores);
  const sum = keys.reduce((value, key) => value + scores[key], 0);
  let normalisedScores = {};
  for (const key of keys) {
    const value = (sum > 0) ? scores[key] / sum * target : scores[key];
    normalisedScores[key] = roundValues ? roundToDecimal(value) : value;
  }
  return normalisedScores;
}

function getConstituencyResult(results, key, defaultValue) {
  if (results.hasOwnProperty(key)) {
    return results[key];
  }
  if (PAST_SUPPORT.hasOwnProperty(key)) {
    return Object
      .getOwnPropertySymbols(PAST_SUPPORT[key])
      .reduce(
        (value, secondKey) => PAST_SUPPORT[key][secondKey] * results[secondKey] / 100 + value,
        0
      );
  }
  return defaultValue;
}

export function adjustConstituencyScores(constituency, scores, groupMapping) {
  let newScores = {};
  const results = constituency.results;
  const keys = Object.getOwnPropertySymbols(groupMapping);
  if (constituency.results) {
    for (const key of keys) {
      const groupKey = groupMapping[key];
      const score = scores[key] || 0;
      if (!newScores.hasOwnProperty(groupKey)) {
        newScores[groupKey] = 0;
      }
      let constituencyResult = getConstituencyResult(results, key, score);
      let previousResult = getConstituencyResult(PREVIOUS_RESULTS, key, score);
      if (constituencyResult) {
        let ratio = 1;
        if (PREVIOUS_RESULTS.hasOwnProperty(key) || PAST_SUPPORT.hasOwnProperty(key)) {
          if (previousResult > 0) {
            ratio = constituencyResult / previousResult;
          } else {
            ratio = 0;
          }
        }
        newScores[groupKey] += score * ratio;
      } else {
        newScores[groupKey] += score;
      }
    }
    if (results.hasOwnProperty(Parties.MN)) {
      newScores[Parties.MN] = results[Parties.MN];
    }
    return normalise(newScores);
  }
  return scores;
}

export function getKnownScores(scores) {
  let knownScores = Object.assign({}, scores);
  delete knownScores[Parties.NIEZDECYDOWANI];
  delete knownScores[Parties.POZOSTALI];
  return knownScores;
}

export function getNormalisedKnownScores(scores) {
  let knownScores = Object.assign({}, scores);
  knownScores[Parties.NIEZDECYDOWANI] = 0;
  knownScores[Parties.POZOSTALI] = 0;
  return normalise(knownScores);
}

export function calculateSeatsInConstituencies(scores, groupMapping, adjustScores = false) {
  let totalSeats = {};
  const totalVotes = totalVotesPerParty(scores, groupMapping, adjustScores);
  for (const c of CONSTITUENCIES) {
    const seats = calculateSeatsInSingleConstituency(scores, groupMapping, c, totalVotes, adjustScores);
    for (const key of Object.getOwnPropertySymbols(seats)) {
      if (!totalSeats.hasOwnProperty(key)) {
        totalSeats[key] = 0;
      }
      totalSeats[key] += seats[key];
    }
  }
  return totalSeats;
}

export function calculateSeatsInSingleConstituency(scores, groupMapping, constituency, totalVotes, adjustScores = false) {
  const groupScores = getGroupScores(scores, groupMapping);
  const adjustedScores = adjustScores ? adjustConstituencyScores(constituency, scores, groupMapping) : groupScores;
  const newScores = getKnownScores(adjustedScores);
  return calculateSeats(newScores, groupScores, constituency.seats, totalVotes, constituency.number);
}

// Calculates seats based on scores.
// - scores used to calculate the seats
// - thresholdScores is used to determine if score is above threshold 
//   (because it's determined globally)
export function calculateSeats(scores, thresholdScores, totalSeats, totalVotes, constituencyNumber = 0) {
  // Object.entries and Object.keys doesn't work with symbols
  const keysToConsider = Object.getOwnPropertySymbols(scores);
  const normalisedThresholdScores = getKnownScores(thresholdScores);
  let keys = [];
  for (const key of keysToConsider) {
    if ((normalisedThresholdScores[key] || 0) >= getThreshold(key)) {
      keys.push(key);
    }
  }
  let seats = Object.fromEntries(keysToConsider.map(key => [key, 0]));
  for (let i = 0; i < totalSeats; ++i) {
    let maxQuotient = 0, winner = null;
    for (const key of keys) {
      let quotient = scores[key] / (1 + seats[key]);
      if (quotient > maxQuotient) {
        maxQuotient = quotient;
        winner = key;
      } else if (quotient === maxQuotient) {
        // If there's a tie, the winner is the one with more total votes.
        // If there's still a tie, we just alternate based on constituency number
        // to resolve the issue when there's no score adjustment.
        if (totalVotes[key] > totalVotes[winner]
          || (totalVotes[key] === totalVotes[winner] && constituencyNumber % 2 === 0)) {
          winner = key;
        }
      }
    }
    seats[winner] += 1;
  }
  return seats;
}

export function getGroupScores(scores, groupMapping) {
  let groups = {};
  // Object.entries and scoresObject.keys doesn't work with symbols
  const keysToConsider = Object.getOwnPropertySymbols(scores);
  for (const key of keysToConsider) {
    let groupKey = groupMapping[key];
    if (!groups.hasOwnProperty(groupKey)) {
      groups[groupKey] = 0;
    }
    groups[groupKey] += scores[key];
  }
  return groups;
}

export function totalForGroup(scores, filterFn) {
  return Object
    .getOwnPropertySymbols(scores)
    .filter(filterFn)
    .reduce((value, key) => value + scores[key], 0);
}

/*
  Finds the minimum score for the `key` party such that the party
  has at least `seatGoal` seats.
*/
export function findWinningResult(scores, seatGoal, adjustScores) {
  const keyOrder = [GroupingOption.GROUP_1, Parties.PIS, Parties.KONFEDERACJA];
  const groupMapping = Object.fromEntries(keyOrder.map(key => [key, key]));
  const key = GroupingOption.GROUP_1;
  const newScores = {
    [Parties.PIS]: scores[Parties.PIS],
    [Parties.KONFEDERACJA]: scores[Parties.KONFEDERACJA],
    [GroupingOption.GROUP_1]: 100 - scores[Parties.PIS] - scores[Parties.KONFEDERACJA],
  };
  let l = 0;
  let r = 100;
  let seats = calculateSeatsInConstituencies(newScores, groupMapping, adjustScores);
  let i = 0;
  let adjustedScores = Object.assign({}, newScores);
  while (l + 0.01 < r && i < 50) {
    // ugly, but adjustment is imperfect so just making sure we don't loop infinitely
    i += 1;
    const middle = (l + r) / 2;
    adjustedScores = updateScores(keyOrder, newScores, key, middle, false);
    seats = calculateSeatsInConstituencies(adjustedScores, groupMapping, adjustScores);
    if (seats[key] < seatGoal) {
      l = middle;
    } else {
      r = middle + 0.01;
    }
  }
  return adjustedScores;
}